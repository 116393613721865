export const preventRefresh = () => {
  window.onbeforeunload = (event) => {
    const e = event || window.event;
    e.preventDefault();
    if (e) {
      e.returnValue = '';
    }
  };
};
export const allowRefresh = () => {
  window.onbeforeunload = null;
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './unFunctionalPage.module.css';
import { IParagraph } from '../../models/IParagraph';
import EmailOrSite from './helper';

interface IUnFunctionalPageProps {
  src: string;
  paragraphs: IParagraph[];
}

const UnFunctionalPage: React.FC<IUnFunctionalPageProps> = ({
  src,
  paragraphs,
}) => {
  const { t } = useTranslation();
  return (
    <div className="container__col-12">
      <h1>{t(`${src}.title`)}</h1>
      {paragraphs.map((p) => (
        <>
          {p.pTitle && (
            <p className={styles.subtitle}>
              {' '}
              <EmailOrSite val={t(`${src}.${p.pTitle}`)} />
            </p>
          )}
          {p.paragraph.map((sp) => (
            <p className={styles.pLine}>
              <EmailOrSite val={t(`${src}.${sp}`)} />
            </p>
          ))}
        </>
      ))}
    </div>
  );
};

export default UnFunctionalPage;

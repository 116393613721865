import {
  HOW_OFTEN_DRINK,
  DOES_SMOKE,
  SMOKED_AMOUNT,
  SMOKED_YEARS,
  HAS_SMOKED,
  MEDICINE_SENSITIVITY_WHAT,
  MEDICINE_SENSITIVITY,
  MEDICINE,
  HAS_RELATIVES_WHO,
  CHRONICLE_DISEASE,
  HAS_RELATIVES,
  HEIGHT,
  HOW_MUCH_TO_DRINK,
  HOW_OFTEN_SIX,
  WEIGHT,
  SMOKED_AMOUNT_OVERVIEW,
  SMOKED_YEARS_OVERVIEW,
  PHONE,
} from '../../constants/preinformationFormKeys';
import { ActionTypes, SettingsActionTypes } from './actions';
import { ISettingsState } from './state';

export const initialSettingsState: ISettingsState = {
  preinfoFormLabels: [
    { [PHONE]: `preInformation.${PHONE}` },
    { [HEIGHT]: `preInformation.${HEIGHT}` },
    { [WEIGHT]: `preInformation.${WEIGHT}` },
    { [CHRONICLE_DISEASE]: `preInformation.${CHRONICLE_DISEASE}` },
    { [HAS_RELATIVES]: `preInformation.${HAS_RELATIVES}` },
    { [HAS_RELATIVES_WHO]: `preInformation.${HAS_RELATIVES_WHO}` },
    { [MEDICINE]: `preInformation.${MEDICINE}` },
    { [MEDICINE_SENSITIVITY]: `preInformation.${MEDICINE_SENSITIVITY}` },
    {
      [MEDICINE_SENSITIVITY_WHAT]: `preInformation.${MEDICINE_SENSITIVITY_WHAT}`,
    },
    { [HAS_SMOKED]: `preInformation.${HAS_SMOKED}` },
    { [SMOKED_YEARS]: `preInformation.${SMOKED_YEARS}` },
    { [SMOKED_AMOUNT]: `preInformation.${SMOKED_AMOUNT}` },
    { [SMOKED_YEARS_OVERVIEW]: `preInformation.${SMOKED_YEARS_OVERVIEW}` },
    { [SMOKED_AMOUNT_OVERVIEW]: `preInformation.${SMOKED_AMOUNT_OVERVIEW}` },
    { [DOES_SMOKE]: `preInformation.${DOES_SMOKE}` },
    { [HOW_OFTEN_DRINK]: `preInformation.${HOW_OFTEN_DRINK}` },
    { [HOW_MUCH_TO_DRINK]: `preInformation.${HOW_MUCH_TO_DRINK}` },
    { [HOW_OFTEN_SIX]: `preInformation.${HOW_OFTEN_SIX}` },
  ],
};

export const SettingsReducer = (
  state: ISettingsState = initialSettingsState,
  action: SettingsActionTypes
): ISettingsState => {
  switch (action.type) {
    case ActionTypes.SetPreinformationLabels:
      return { ...state, preinfoFormLabels: action.payload };
    default:
      return state;
  }
};

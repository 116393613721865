import React from 'react';
import Layout from '../../features/layout/layout';
import PreInformationPageContent from '../../features/preInformationPageContent/preInformationPageContent';
import { preventRefresh } from '../../utils/preventRefresh';
import styles from './preInformationPage.module.scss';

const PreInformationPage: React.FC = () => {
  preventRefresh();
  return (
    <Layout>
      <div className="container__row">
        <div className={`container__col-12 ${styles.wrapper}`}>
          <PreInformationPageContent />
        </div>
      </div>
    </Layout>
  );
};

export default PreInformationPage;

import { ActionTypes, UsersActionTypes } from './actions';
import { UsersState } from './state';

export const initialUsersState: UsersState = {
  isFetchingUserInfo: false,
  dOB: '',
  sampleCode: '',
  hasLoginError: false,
};

export const UsersReducer = (
  state: UsersState = initialUsersState,
  action: UsersActionTypes
): UsersState => {
  switch (action.type) {
    case ActionTypes.SetLoggedIn:
      return {
        ...state,

        isFetchingUserInfo: false,
        dOB: action.payload.dOB,
        sampleCode: action.payload.sampleCode,
        token: action.payload.token,
        preinformationDate: action.payload.preinformationDate,
      };
    case ActionTypes.FetchingUserInfo:
      return {
        ...state,
        isFetchingUserInfo: true,
      };
    case ActionTypes.LogoutUser:
      return {
        ...state,
        token: undefined,
        isFetchingUserInfo: false,

        hasLoginError: action.payload,
      };
    default:
      return state;
  }
};

import { AnyAction, CombinedState, combineReducers } from 'redux';
import {
  AnnouncementsReducer,
  initialAnnouncementState,
} from './announcements/reducer';
import { AnnouncementsState } from './announcements/state';
import {
  PreInfoFormReducer,
  initialPreinformationFormState,
} from './preinfoForm/reducer';
import { IPreinformationFormState } from './preinfoForm/state';
import { initialSettingsState, SettingsReducer } from './settings/reducer';
import { ISettingsState } from './settings/state';
import { initialUsersState, UsersReducer } from './users/reducer';
import { UsersState } from './users/state';

export interface RootState {
  user: UsersState;
  preinfoForm: IPreinformationFormState;
  settings: ISettingsState;
  announcements: AnnouncementsState;
}

export const initialRootState = {
  user: initialUsersState,
  preinfoForm: initialPreinformationFormState,
  settings: initialSettingsState,
  announcements: initialAnnouncementState,
};

export const appReducer = combineReducers<RootState>({
  user: UsersReducer,
  preinfoForm: PreInfoFormReducer,
  announcements: AnnouncementsReducer,
  settings: SettingsReducer,
});

export const rootReducer = (
  state: CombinedState<RootState> | undefined,
  action: AnyAction
) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'LOGOUT_USER') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

import defineLanguage from './defineLanguage';

export const langPath = (path: string) => {
  const lang = defineLanguage();

  if (lang === 'fi') return path;

  return `/${lang}${path}`;
};

export const langPathWithLang = (path: string, lang: string) =>
  `${lang === 'fi' ? '' : `/${lang}`}${path}`;

export const getCurrentPath = () => {
  const pathArr = window.location.pathname.split('/');
  return `/${pathArr[pathArr.length - 1]}`;
};

import React from 'react';
import Layout from '../../features/layout/layout';
import ThankyouPageContent from '../../features/thankyouPageContent/thankyouPageContent';
import { allowRefresh } from '../../utils/preventRefresh';

const ThankyouPage: React.FC = () => {
  allowRefresh();
  return (
    <Layout showHome={true}>
      <div className="container__row">
        <div className="container__col-12">
          <ThankyouPageContent />
        </div>
      </div>
    </Layout>
  );
};

export default ThankyouPage;

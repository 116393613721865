import React from 'react';
import list from '../../constants/lists';
import Layout from '../../features/layout/layout';
import UnFunctionalPage from '../../features/unFunctionalPage/unFunctionalPage';

const AccessibilityStatement: React.FC = () => {
  return (
    <Layout showHome={false}>
      <div className="container__row">
        <div className="container__col-12">
          <UnFunctionalPage
            src="accessibilityStatement"
            paragraphs={list.accessibilityStatement}
          />
        </div>
      </div>
    </Layout>
  );
};

export default AccessibilityStatement;

import { useField } from 'formik';
import React from 'react';
import styles from './inputContainer.module.css';

interface IInputContainer {
  name: string;
  className?: string;
}

const InputContainer: React.FC<IInputContainer> = ({
  name,
  className,
  children,
}) => {
  const [field, error] = useField(name);

  return (
    <div
      className={`${styles.componentWrapper} ${
        error.error ? styles.error : ''
      } ${className || ''}`}
    >
      {children}
    </div>
  );
};

export default InputContainer;

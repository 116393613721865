import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './choiceBox.module.scss';

interface IChoiceProps {
  title: string;
  desc: string;
  onclick?: () => void;
  aditionalInfo?: string;
  enable: boolean;
}

const ChoiceBox: React.FC<IChoiceProps> = ({
  title,
  desc,
  enable,
  onclick,
  aditionalInfo,
}) => {
  return (
    <button
      type="button"
      className={`${styles.container} ${enable ? '' : styles.disable}`}
      onClick={() => (onclick && enable ? onclick() : null)}
    >
      <h3 className={styles.title}>{title}</h3>
      <FontAwesomeIcon
        className={styles.icon}
        color="#1B4A83"
        icon={faAngleRight}
      />
      <p className={styles.content}>{desc}</p>
      <p className={styles.aditionalInfo}>{aditionalInfo || ''}</p>
    </button>
  );
};

export default ChoiceBox;

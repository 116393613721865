import Axios, { AxiosResponse } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IAnnouncement } from '../../models/IAnnouncement';
import { AnnouncementsState } from './state';
import stripHtml from '../../utils/stripHtml';
import defineLanguage from '../../utils/defineLanguage';

export enum ActionTypes {
  FetchingSuccessful = 'FETCHING_ANNOUNCEMENTS_SUCCESSFUL',
  FetchingError = 'FETCHING_ANNOUNCEMENTS_ERROR',
  Fetching = 'FETCHING_ANNOUNCEMENTS',
}

interface FetchingSuccessfulAction extends Action {
  type: ActionTypes.FetchingSuccessful;
  payload: IAnnouncement[];
}
interface FetchingSuccessfulAction extends Action {
  type: ActionTypes.FetchingSuccessful;
  payload: IAnnouncement[];
}

interface FetchingErrorAction extends Action {
  type: ActionTypes.FetchingError;
  payload: string;
}

interface FetchingAction extends Action {
  type: ActionTypes.Fetching;
}

export type AnnouncementActionTypes =
  | FetchingSuccessfulAction
  | FetchingErrorAction
  | FetchingAction;

type AnnouncementsThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AnnouncementsState,
  unknown,
  AnnouncementActionTypes
>;

export const FetchAnnouncementData = (): AnnouncementsThunk => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    if (!state.fetching && !state.haveData) {
      dispatch({
        type: ActionTypes.Fetching,
      });
      const language = defineLanguage();

      Axios.get(
        `${process.env.REACT_APP_API_URL}/announcements?locale=${language}`
      ).then((res: AxiosResponse<any>) => {
        const announcements: IAnnouncement[] = res.data;
        if (announcements.length) {
          dispatch({
            type: ActionTypes.FetchingSuccessful,
            payload: announcements,
          });
        }
      });
    }
  } catch (e) {
    dispatch({
      type: ActionTypes.FetchingError,
      payload: 'Error fetching announcements!',
    });
  }
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { langPath } from '../../utils/pathUtils';
import styles from './terms.module.scss';

const TermService: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.registryDescription}>
      <a className={styles.registryDescription} target="_blank" rel="noreferrer" href={langPath('/tietoa-palvelusta')}>{t('loginPage.termsOfService')}</a>
      <a className={styles.registryDescription} target="_blank" rel="noreferrer" href={langPath('/personal-data-processing')}>{t('loginPage.privacyPolicy')}</a>
      <a className={styles.registryDescription} target="_blank" rel="noreferrer" href={langPath('/saavutettavuusseloste')}>{t('loginPage.accessibilityStatement')}</a>
    </div>
  );
};

export default TermService;

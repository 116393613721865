import moment from 'moment';

/**
 * Validate date of birth checksum
 * @param dob SSN
 */

const validateDOB = (dob: string | null | undefined): boolean => {
  const maxAge = 100;
  const birthDate = moment(dob?.trim(), 'DDMMYY');
  if (!birthDate.isValid()) {
    return false;
  }
  return moment().diff(birthDate, 'years') < maxAge;
};

export default validateDOB;

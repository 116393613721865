import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/HUS_logo.png';
import { RootState } from '../../state/rootReducer';
import { langPath } from '../../utils/pathUtils';
import ChangeLangButtons from '../changeLangButton/changeLangButtons';
import styles from './header.module.scss';

interface IHeaderProps {
  showLogout?: boolean;
  showHome?: boolean;
}

const Header: React.FC<IHeaderProps> = ({ showHome = true }) => {
  const { token } = useSelector((state: RootState) => state.user);

  const { t } = useTranslation();

  return (
    <div className="container--fluid">
      <div className={`${styles.logoWrapper} ${styles.top}`}>
        <img className={styles.logo} src={logo} alt="HUS logo" />
      </div>
      <ChangeLangButtons />
      <div className={`container__row ${styles.bottom}`}>
        <div className={styles.bottomContent}>
          {token && <Link to={langPath('/logout')}>{t('links.logout')}</Link>}
          {showHome && <Link to={langPath('/')}>{t('links.loginPage')}</Link>}
        </div>
      </div>
    </div>
  );
};

export default Header;

import React from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@husepidemic/epidemicdesign/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBeer, faWineGlass } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { langPath } from '../../utils/pathUtils';
import FocusError from '../../components/focusScrollFormError/focusScrollError';
import CheckboxGroup from '../../components/checkboxGroup/checkboxGroup';
import { IChecked } from '../../models/IChecked';
import styles from './preInformationPageContent.module.scss';
import RadioGroup from '../../components/radioGroup/radioGroup';
import { RootState } from '../../state/rootReducer';
import { setPreinfoFormValues } from '../../state/preinfoForm/actions';
import createPreinfoConstants from './PreInformationPageHelper';
import {
  HOW_OFTEN_DRINK,
  DOES_SMOKE,
  SMOKED_AMOUNT,
  SMOKED_YEARS,
  HAS_SMOKED,
  MEDICINE_SENSITIVITY_WHAT,
  MEDICINE_SENSITIVITY,
  MEDICINE,
  HAS_RELATIVES_WHO,
  CHRONICLE_DISEASE,
  HAS_RELATIVES,
  HEIGHT,
  HOW_MUCH_TO_DRINK,
  HOW_OFTEN_SIX,
  WEIGHT,
  PHONE,
} from '../../constants/preinformationFormKeys';
import InputContainer from '../../components/inputContainer/inputContainer';
import ErrorsList from '../../components/errorsList/errorsList';
import InputWithTitle from '../../components/formInput/inputWithTitle';

const PreInformationPageContent: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const preinfoForm = useSelector((state: RootState) => state.preinfoForm);

  const history = useHistory();

  const constants = createPreinfoConstants(t);

  return (
    <div>
      <h1 className={styles.title}>{t('preInformation.title')}</h1>
      <p className="info-text">{t('preInformation.infoText')}</p>
      <p className="guide-text">{t('preInformation.guideText')}</p>
      <Formik
        validationSchema={constants.validationSchema}
        initialValues={preinfoForm}
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={(val) => {
          dispatch(setPreinfoFormValues(val));
          history.push(langPath('/summary'));
        }}
      >
        {({ values, setFieldValue, errors, handleSubmit }) => (
          <Form>
            <ErrorsList errors={errors} src="preInformation" />
            <FocusError />
            <InputContainer name={PHONE}>
              <div className={styles.number}>1</div>
              <InputWithTitle
                className="input-component-wrapper container__col-6"
                name={PHONE}
                label={t(`preInformation.${PHONE}`)}
                inputMode="numeric"
              />
            </InputContainer>

            <InputContainer name={HEIGHT}>
              <div className={styles.number}>2</div>
              <InputWithTitle
                className="input-component-wrapper container__col-6"
                required
                name={HEIGHT}
                label={t(`preInformation.${HEIGHT}`)}
                inputMode="numeric"
              />
            </InputContainer>
            <InputContainer name={WEIGHT}>
              <div className={styles.number}>3</div>
              <InputWithTitle
                className="input-component-wrapper container__col-6"
                required
                name={WEIGHT}
                label={t(`preInformation.${WEIGHT}`)}
                inputMode="numeric"
              />
            </InputContainer>
            <InputContainer name={CHRONICLE_DISEASE}>
              <div className={styles.number}>4</div>
              <CheckboxGroup
                required
                title={t(`preInformation.${CHRONICLE_DISEASE}`)}
                name={CHRONICLE_DISEASE}
                value={values[CHRONICLE_DISEASE]}
                items={constants.chronicDiseaseItems}
                onChange={(e) => setFieldValue(CHRONICLE_DISEASE, e)}
                error={errors[CHRONICLE_DISEASE]}
              />
            </InputContainer>
            <InputContainer name={HAS_RELATIVES}>
              <div className={styles.number}>5</div>
              <RadioGroup
                label={t(`preInformation.${HAS_RELATIVES}`)}
                name={HAS_RELATIVES}
                className={`${styles.radioGroup} ${styles.hasRelatives}`}
                items={constants[HAS_RELATIVES]}
                required
                onChange={(e) => {
                  if (e !== '2') {
                    const falsyChecked: IChecked = {};
                    Object.keys(values[HAS_RELATIVES_WHO]).forEach((el) => {
                      falsyChecked[el] = false;
                    });
                    setFieldValue(HAS_RELATIVES_WHO, falsyChecked);
                  }
                  setFieldValue(HAS_RELATIVES, JSON.parse(e));
                }}
                error={errors[HAS_RELATIVES]}
              />
            </InputContainer>
            <InputContainer
              name={HAS_RELATIVES_WHO}
              className={styles.subInputs}
            >
              <CheckboxGroup
                title={t(`preInformation.${HAS_RELATIVES_WHO}`)}
                name={HAS_RELATIVES_WHO}
                value={values[HAS_RELATIVES_WHO]}
                items={constants.hasRelativesWhoItems}
                onChange={(e) => setFieldValue(HAS_RELATIVES_WHO, e)}
                error={
                  values[HAS_RELATIVES] === 2 ? errors[HAS_RELATIVES_WHO] : ''
                }
                required={values[HAS_RELATIVES] === 2}
                disabled={values[HAS_RELATIVES] !== 2}
              />
            </InputContainer>
            <InputContainer name={MEDICINE}>
              <div className={styles.number}>6</div>
              <CheckboxGroup
                title={t(`preInformation.${MEDICINE}`)}
                name={MEDICINE}
                value={values[MEDICINE]}
                items={constants.medicineItems}
                onChange={(e) => setFieldValue(MEDICINE, e)}
                error={errors[MEDICINE]}
                required
              />
            </InputContainer>
            <InputContainer name={MEDICINE_SENSITIVITY}>
              <div className={styles.number}>7</div>
              <div>
                <RadioGroup
                  onChange={(e) => {
                    if (!JSON.parse(e)) {
                      setFieldValue(MEDICINE_SENSITIVITY_WHAT, '');
                    }
                    setFieldValue(MEDICINE_SENSITIVITY, JSON.parse(e));
                  }}
                  label={t(`preInformation.${MEDICINE_SENSITIVITY}`)}
                  name={MEDICINE_SENSITIVITY}
                  className={styles.radioGroup}
                  items={constants.yesOrNo}
                  required
                  error={errors[MEDICINE_SENSITIVITY]}
                />
              </div>
            </InputContainer>
            <InputContainer
              name={MEDICINE_SENSITIVITY_WHAT}
              className={styles.subInputs}
            >
              <InputWithTitle
                disabled={!values[MEDICINE_SENSITIVITY]}
                className="input-component-wrapper container__col-6"
                name={MEDICINE_SENSITIVITY_WHAT}
                label={t(`preInformation.${MEDICINE_SENSITIVITY_WHAT}`)}
                labelClassName={
                  !values[MEDICINE_SENSITIVITY] ? styles.disabledLabel : ''
                }
                hideErros={!values[MEDICINE_SENSITIVITY]}
                required={!!values[MEDICINE_SENSITIVITY]}
              />
            </InputContainer>
            <InputContainer name={HAS_SMOKED}>
              <div className={styles.number}>8</div>
              <div>
                <RadioGroup
                  onChange={(e) => {
                    if (!JSON.parse(e)) {
                      setFieldValue(SMOKED_YEARS, '');
                      setFieldValue(SMOKED_AMOUNT, '');
                      setFieldValue(DOES_SMOKE, '');
                    }
                    setFieldValue(HAS_SMOKED, JSON.parse(e));
                  }}
                  label={t(`preInformation.${HAS_SMOKED}`)}
                  name={HAS_SMOKED}
                  className={styles.radioGroup}
                  items={constants.yesOrNo}
                  error={errors[HAS_SMOKED]}
                  required
                />
              </div>
            </InputContainer>
            <div className={`${styles.smokeContainer} container__col-6`}>
              <InputContainer name={SMOKED_YEARS} className={styles.subInputs}>
                <InputWithTitle
                  label={t(`preInformation.${SMOKED_YEARS}`)}
                  name={SMOKED_YEARS}
                  disabled={!values[HAS_SMOKED]}
                  required={!!values[HAS_SMOKED]}
                  className="input-component-wrapper container__col-4"
                  labelClassName={
                    !values[HAS_SMOKED] ? styles.disabledLabel : ''
                  }
                  hideErros={!values[HAS_SMOKED]}
                />
              </InputContainer>
              <InputContainer name={SMOKED_AMOUNT} className={styles.subInputs}>
                <InputWithTitle
                  label={t(`preInformation.${SMOKED_AMOUNT}`)}
                  name={SMOKED_AMOUNT}
                  hideErros={!values[HAS_SMOKED]}
                  disabled={!values[HAS_SMOKED]}
                  required={!!values[HAS_SMOKED]}
                  className="input-component-wrapper container__col-4"
                  labelClassName={
                    !values[HAS_SMOKED] ? styles.disabledLabel : ''
                  }
                />
              </InputContainer>
            </div>
            <InputContainer name={DOES_SMOKE}>
              <div className={styles.number}>9</div>
              <RadioGroup
                onChange={(e) => setFieldValue(DOES_SMOKE, JSON.parse(e))}
                label={t(`preInformation.${DOES_SMOKE}`)}
                name={DOES_SMOKE}
                disabled={!values[HAS_SMOKED]}
                className={styles.radioGroup}
                items={constants.yesOrNo}
                error={values[HAS_SMOKED] ? errors[DOES_SMOKE] : ''}
                required={!!values[HAS_SMOKED]}
              />
            </InputContainer>
            <InputContainer name={HOW_OFTEN_DRINK}>
              <div className={styles.number}>10</div>
              <div>
                <RadioGroup
                  label={t(`preInformation.${HOW_OFTEN_DRINK}`)}
                  name={HOW_OFTEN_DRINK}
                  onChange={(e) => {
                    if (e === '1') {
                      setFieldValue(HOW_MUCH_TO_DRINK, null);
                      setFieldValue(HOW_OFTEN_SIX, null);
                    }
                    setFieldValue(HOW_OFTEN_DRINK, JSON.parse(e));
                  }}
                  className={styles.radioGroup}
                  items={constants[HOW_OFTEN_DRINK]}
                  error={
                    errors[HOW_OFTEN_DRINK] ? t('forms.requiredField') : ''
                  }
                  required
                />
                <div className={`${styles.alcoholIconWrapper} container__row`}>
                  <div
                    className={`${styles.alcoholIconLeftWrapper} container__col-12 container__col-sm-6`}
                  >
                    <div className={styles.alcoholIcons}>
                      <FontAwesomeIcon size="2x" icon={faWineGlass} />
                    </div>
                    <button
                      type="button"
                      className={styles.alcoholDetailsWrapper}
                    >
                      <span>{t('alcoholAmount.title-1')}</span>
                      <span>{t('alcoholAmount.description-1-1')}</span>
                      <span>{t('alcoholAmount.description-1-2')}</span>
                      <span>{t('alcoholAmount.description-1-3')}</span>
                    </button>
                  </div>
                  <div className={styles.alcoholIconMiddle}>&nbsp;</div>
                  <div
                    className={`${styles.alcoholIconRightWrapper} container__col-12 container__col-sm-6`}
                  >
                    <div className={styles.alcoholIcons}>
                      <FontAwesomeIcon size="2x" icon={faBeer} />
                    </div>
                    <button
                      type="button"
                      className={styles.alcoholDetailsWrapper}
                    >
                      <span>{t('alcoholAmount.title-2')}</span>
                      <span>{t('alcoholAmount.description-2-1')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </InputContainer>
            <InputContainer name={HOW_MUCH_TO_DRINK}>
              <div className={styles.number}>11</div>
              <RadioGroup
                label={t(`preInformation.${HOW_MUCH_TO_DRINK}`)}
                onChange={(e) =>
                  setFieldValue(HOW_MUCH_TO_DRINK, JSON.parse(e))
                }
                disabled={
                  !values[HOW_OFTEN_DRINK] || values[HOW_OFTEN_DRINK] === 1
                }
                name={HOW_MUCH_TO_DRINK}
                className={styles.radioGroup}
                items={constants[HOW_MUCH_TO_DRINK]}
                error={
                  errors[HOW_MUCH_TO_DRINK] && values[HOW_OFTEN_DRINK]
                    ? t('forms.requiredField')
                    : ''
                }
                required={
                  !!values[HOW_OFTEN_DRINK] && values[HOW_OFTEN_DRINK] !== 1
                }
              />
            </InputContainer>
            <InputContainer name={HOW_OFTEN_SIX}>
              <div className={styles.number}>12</div>
              <RadioGroup
                label={t(`preInformation.${HOW_OFTEN_SIX}`)}
                name={HOW_OFTEN_SIX}
                disabled={
                  !values[HOW_OFTEN_DRINK] || values[HOW_OFTEN_DRINK] === 1
                }
                onChange={(e) => setFieldValue(HOW_OFTEN_SIX, JSON.parse(e))}
                className={styles.radioGroup}
                items={constants[HOW_OFTEN_SIX]}
                error={
                  errors[HOW_OFTEN_SIX] && values[HOW_OFTEN_DRINK]
                    ? t('forms.requiredField')
                    : ''
                }
                required={
                  !!values[HOW_OFTEN_DRINK] && values[HOW_OFTEN_DRINK] !== 1
                }
              />
            </InputContainer>
            <div className="buttons-wrapper container__row">
              <Button
                type="button"
                onClick={handleSubmit}
                className={`${styles.confirmBtn} container__col-12 container__col-sm-5`}
                label={t('buttons.continue')}
              />
              <div className="container__col-sm-2">&nbsp;</div>
              <Button
                type="button"
                className={`${styles.cancelBtn} container__col-12 container__col-sm-5`}
                onClick={() => history.replace(langPath('/home'))}
                label={t('buttons.cancel')}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PreInformationPageContent;

import { Button } from '@husepidemic/epidemicdesign/button';
import InputWithTitle from '@husepidemic/epidemicdesign/inputWithTitle';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import ErrorListing from '../../components/errorListing/errorListing';
import InputContainer from '../../components/inputContainer/inputContainer';
import TermService from '../../components/termService/termService';
import {
  CONSENT,
  DATE_OF_BIRTH,
  SAMPLE_CODE,
} from '../../constants/loginFormKeys';
import { RootState } from '../../state/rootReducer';
import { LoginUser } from '../../state/users/actions';
import validateDOB from '../../utils/validateDOB';
import AnnouncementBox from '../announcementBox/announcementBox';
import styles from './loginPageContent.module.scss';
import i18n from '../../i18n';
import engImage from '../../assets/images/näytetunnusOhjeEN.png';
import fiImage from '../../assets/images/näytetunnusOhjeFI.png';
import svImage from '../../assets/images/näytetunnusOhjeSV.png';

const FrontPageContent: React.FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { isFetchingUserInfo, hasLoginError } = useSelector(
    (state: RootState) => state.user
  );

  const initialValues = {
    [SAMPLE_CODE]: '',
    [DATE_OF_BIRTH]: '',
    [CONSENT]: false,
  };

  const validationSchema = Yup.object().shape({
    [SAMPLE_CODE]: Yup.string().required(t('forms.requiredField')),
    [DATE_OF_BIRTH]: Yup.string()
      .test(DATE_OF_BIRTH, t('forms.maxChar6'), (v) => v?.trim().length === 6)
      .test(DATE_OF_BIRTH, t('forms.invalidDOB'), (val) => {
        if (val?.trim() === '') return true;
        return validateDOB(val);
      }),
  });

  let loginInstructions;

  if (i18n.language === 'en') {
    loginInstructions = engImage;
  } else if (i18n.language === 'sv') {
    loginInstructions = svImage;
  } else {
    loginInstructions = fiImage;
  }

  return (
    <div>
      <h1>{t('loginPage.title')}</h1>
      <AnnouncementBox />
      <p className={styles.infoText}>{t('loginPage.infoText')}</p>
      <div className={styles.loginColumnContainer}>
        <div className={styles.column1}>
          <h2>{t('loginPage.subTitle')}</h2>
          <Formik
            initialValues={initialValues}
            onSubmit={(vals) => {
              dispatch(
                LoginUser(vals[SAMPLE_CODE].trim(), vals[DATE_OF_BIRTH].trim(), t)
              );
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
          >
            {({ errors, setFieldValue }) => (
              <Form>
                {(Object.keys(errors).length !== 0 || hasLoginError) &&
                  !isFetchingUserInfo ? (
                  <ErrorListing
                    label={t('forms.frontPageErrorLabel')}
                    items={[t('forms.frontPageErrorDescription')]}
                  />
                ) : null}

                <InputContainer name={SAMPLE_CODE}>
                  <InputWithTitle
                    labelClassName="input-title"
                    className={styles.inputField}
                    label={t('loginPage.yourCode')}
                    name={SAMPLE_CODE}
                    onChange={(v) =>
                      setFieldValue(
                        SAMPLE_CODE,
                        v.target.value?.toLocaleUpperCase()
                      )
                    }
                    hideErros={true}
                  />
                </InputContainer>
                <InputContainer name={DATE_OF_BIRTH}>
                  <InputWithTitle
                    labelClassName="input-title"
                    className={styles.inputField}
                    label={t('loginPage.dateOfBirth')}
                    name={DATE_OF_BIRTH}
                    hideErros={true}
                  />
                </InputContainer>
                <Button
                  type="submit"
                  label={t('loginPage.submitBtn')}
                  className={styles.buttonLogin}
                  disabled={isFetchingUserInfo}
                />
              </Form>
            )}
          </Formik>
        </div>
        <div className={styles.column2}>
          <img
            alt="Login Instructions"
            src={loginInstructions}
          />
        </div>
      </div>
      <TermService />
    </div>
  );
};

export default FrontPageContent;

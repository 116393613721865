import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import defineLanguage from './utils/defineLanguage';
import translationFi from './locales/fi/strings';
import translationSv from './locales/sv/strings';
import translationEn from './locales/en/strings';

const locale = defineLanguage();
export enum Languages {
  FI = 'fi',
  SV = 'sv',
  EN = 'en',
}
i18n.use(initReactI18next).init({
  resources: {},
  lng: locale,
  fallbackLng: Languages.FI,
  keySeparator: '.',
  interpolation: {
    escapeValue: true, // react already safes from xss
  },
});

i18n.addResourceBundle(Languages.FI, 'translation', translationFi);
i18n.addResourceBundle(Languages.SV, 'translation', translationSv);
i18n.addResourceBundle(Languages.EN, 'translation', translationEn);

export default i18n;

import React from 'react';
import { useTranslation } from 'react-i18next';
import list from '../../constants/lists';
import UnFunctionalPage from '../unFunctionalPage/unFunctionalPage';

const PrivacyPolicyContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="container__col-12">
      <UnFunctionalPage src="privacyPolicy" paragraphs={list.privacyPolicy} />

      <ul>
        <li><a href="https://www.ekhva.fi/eksote/tietoa-meista/hyodyllista-tietoa/tietosuoja/" target="_blank" rel="noreferrer">Etelä-Karjalan hyvinvointialue</a></li>
        <li><a href="https://etelasavonha.fi/asiakkaan-opas/asiakas-ja-potilasturvallisuus-ja-valvonta/rekisterit/" target="_blank" rel="noreferrer">Etelä-Savon hyvinvointialue</a></li>        
        <li><a href="https://www.hus.fi/tietoa-meista/hallinto-ja-paatoksenteko/tietosuoja#potilasrekisteri%C3%A4-koskevat-inf" target="_blank" rel="noreferrer">HUS-yhtymä</a></li>
        <li><a href="https://www.hel.fi/static/liitteet/kanslia/rekisteriselosteet/Sote/Sote-EU-Potilasrekisteri.pdf" target="_blank" rel="noreferrer">Helsinki</a></li>
        <li><a href="https://itauusimaa.fi/tietosuoja/" target="_blank" rel="noreferrer">Itä-Uudenmaan hyvinvointialue</a></li>
        <li><a href="https://www.keusote.fi/etusivu/meilla-asiakkaana/tietosuoja/" target="_blank" rel="noreferrer">Keski-Uudenmaan hyvinvointialue</a></li>
        <li><a href="https://kymenhva.fi/ajanvaraus-ja-asiointi/henkilotietojen-kasittely/rekisteroityjen-informointi/" target="_blank" rel="noreferrer">Kymenlaakson hyvinvointialue</a></li>
        <li><a href="https://www.luvn.fi/fi/tietosuojaselosteet" target="_blank" rel="noreferrer">Länsi-Uudenmaan hyvinvointialue</a></li>
        <li><a href="https://pshyvinvointialue.fi/tietosuoja" target="_blank" rel="noreferrer">Pohjois-Savon hyvinvointialue</a></li>
        <li><a href="https://vakehyva.fi/fi/henkilotietojen-kasittely" target="_blank" rel="noreferrer">Vantaan ja Keravan hyvinvointialue</a></li>
      </ul>
    </div>
  );
};

export default PrivacyPolicyContent;
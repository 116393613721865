import { Action } from 'redux';
import { IPreinfoFormLabels } from './state';

export enum ActionTypes {
  SetPreinformationLabels = 'SET_PREINFORMATION_LABELS',
}

interface SetPreinformationLabelsAction extends Action {
  type: ActionTypes.SetPreinformationLabels;
  payload: IPreinfoFormLabels[];
}

export type SettingsActionTypes = SetPreinformationLabelsAction;

import { RootState } from '../state/rootReducer';
import { IKeyOptions, IPreinformation } from '../models/IPreinformation';
import {
  CHRONICLE_DISEASE,
  DOES_SMOKE,
  HAS_RELATIVES,
  HAS_RELATIVES_WHO,
  HAS_SMOKED,
  HEIGHT,
  HOW_MUCH_TO_DRINK,
  HOW_OFTEN_DRINK,
  HOW_OFTEN_SIX,
  MEDICINE,
  MEDICINE_SENSITIVITY,
  MEDICINE_SENSITIVITY_WHAT,
  PHONE,
  SMOKED_AMOUNT,
  SMOKED_YEARS,
  WEIGHT,
} from '../constants/preinformationFormKeys';
import { IChecked } from '../models/IChecked';
import { IPreinformationFormState } from '../state/preinfoForm/state';

const allKeys: { [key: string]: IKeyOptions } = {
  parents: 1,
  siblings: 2,
  kids: 3,
  otherRelative: 4,
  blood: 1,
  asetylSalisyd: 2,
  otherMedicine: 3,
  diabetes: 1,
  flapFailure: 2,
  severLungOrHeart: 4,
  heartFailure: 3,
  severDisability: 5,
};

const convertToKeyArray = (inputs: IChecked): IKeyOptions[] => {
  const intit: IKeyOptions[] = [];
  Object.keys(inputs).forEach((el) => {
    if (!el.includes('NoneOfAbove') && inputs[el]) {
      intit.push(allKeys[el]);
    }
  });
  return intit;
};

const convertToNumber = (val: string): number | null => {
  if (val && +val) {
    return +val;
  }
  return null;
};

export default (state: IPreinformationFormState): IPreinformation => {
  return {
    [PHONE]: state[PHONE],
    [HEIGHT]: convertToNumber(state[HEIGHT]),
    [WEIGHT]: convertToNumber(state[WEIGHT]),
    [HAS_RELATIVES]: state[HAS_RELATIVES],

    [MEDICINE_SENSITIVITY]: state[MEDICINE_SENSITIVITY],
    [MEDICINE_SENSITIVITY_WHAT]: state[MEDICINE_SENSITIVITY_WHAT] || '',

    [HAS_SMOKED]: state[HAS_SMOKED],
    [SMOKED_YEARS]: convertToNumber(state[SMOKED_YEARS]),
    [SMOKED_AMOUNT]: convertToNumber(state[SMOKED_AMOUNT]),
    [DOES_SMOKE]: state[HAS_SMOKED],

    [HOW_OFTEN_DRINK]: state[HOW_OFTEN_DRINK] || null,
    [HOW_MUCH_TO_DRINK]: state[HOW_MUCH_TO_DRINK] || null,
    [HOW_OFTEN_SIX]: state[HOW_OFTEN_SIX] || null,

    [HAS_RELATIVES_WHO]: convertToKeyArray(state[HAS_RELATIVES_WHO]),
    [CHRONICLE_DISEASE]: convertToKeyArray(state[CHRONICLE_DISEASE]),
    [MEDICINE]: convertToKeyArray(state[MEDICINE]),
  };
};

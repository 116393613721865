import React, { memo } from 'react';
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';

import LogoutPage from '../pages/logoutPage/logoutPage';
import OverviewPage from '../pages/overviewPage/overviewPage';
import PrivacyPolicyPage from '../pages/privacyPolicyPage/privacyPolicyPage';
import ServiceInformationPage from '../pages/serviceInformationPage/serviceInformationPage';
import PreInformationPage from '../pages/preInformationPage/preInformationPage';
import ThankyouPage from '../pages/thankyouPage/thankyouPage';
import AuthGuard from './guards/authGuard';
import LaunguageGuard from './guards/LaunguageGuard';
import LoginPage from '../pages/loginPage/loginPage';
import HomePage from '../pages/homePage/homePage';
import OrderKitPage from '../pages/orderKitPage/orderKitPage';
import AccessibilityStatement from '../pages/accessibilityStatement/accessibilityStatement';

const RouterComponent: React.FC = () => {
  return (
    <Router>
      <Route
        component={(props: RouteComponentProps) => LaunguageGuard(props)}
      />
      <Switch>
        <AuthGuard exact path="/sv/thanks" component={ThankyouPage} />
        <AuthGuard exact path="/en/thanks" component={ThankyouPage} />
        <AuthGuard exact path="/thanks" component={ThankyouPage} />

        <AuthGuard exact path="/sv/summary" component={OverviewPage} />
        <AuthGuard exact path="/en/summary" component={OverviewPage} />
        <AuthGuard exact path="/summary" component={OverviewPage} />

        <AuthGuard exact path="/prerequisites" component={PreInformationPage} />
        <AuthGuard
          exact
          path="/sv/prerequisites"
          component={PreInformationPage}
        />
        <AuthGuard
          exact
          path="/en/prerequisites"
          component={PreInformationPage}
        />

        <AuthGuard exact path="/home" component={HomePage} />
        <AuthGuard exact path="/sv/home" component={HomePage} />
        <AuthGuard exact path="/en/home" component={HomePage} />

        <AuthGuard exact path="/orderkit" component={OrderKitPage} />
        <AuthGuard exact path="/sv/orderkit" component={OrderKitPage} />
        <AuthGuard exact path="/en/orderkit" component={OrderKitPage} />

        <AuthGuard exact path="/en/logout" component={LogoutPage} />
        <AuthGuard exact path="/sv/logout" component={LogoutPage} />
        <AuthGuard exact path="/logout" component={LogoutPage} />
        <Route path="/personal-data-processing" component={PrivacyPolicyPage} />
        <Route
          path="/sv/personal-data-processing"
          component={PrivacyPolicyPage}
        />
        <Route
          path="/en/personal-data-processing"
          component={PrivacyPolicyPage}
        />
        <Route path="/tietoa-palvelusta" component={ServiceInformationPage} />
        <Route
          path="/sv/tietoa-palvelusta"
          component={ServiceInformationPage}
        />
        <Route
          path="/en/tietoa-palvelusta"
          component={ServiceInformationPage}
        />
        <Route
          exact
          path="/saavutettavuusseloste"
          component={AccessibilityStatement}
        />
        <Route
          exact
          path="/en/saavutettavuusseloste"
          component={AccessibilityStatement}
        />
        <Route
          exact
          path="/sv/saavutettavuusseloste"
          component={AccessibilityStatement}
        />
        <Route exact path="/en" component={LoginPage} />
        <Route exact path="/sv" component={LoginPage} />
        <Route exact path="/" component={LoginPage} />
      </Switch>
    </Router>
  );
};

export default memo(RouterComponent);

import React from 'react';

const EmailOrSite: React.FC<{ val: string }> = ({ val }) => {
  const result: any[] = [];
  val.split(' ').forEach((el) => {
    if (el.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/gm)) {
      return result.push(
        <>
          {' '}
          <a
            href={`mailto:${
              el[el.length - 1] === '.' ? el.slice(0, el.length - 1) : el
            }?body=" "`}
          >
            {el}
          </a>{' '}
        </>
      );
    }
    if (el.includes('www')) {
      return result.push(
        <>
          {' '}
          <a href={`https://${el.replace('https://', '')}`}>{el}</a>{' '}
        </>
      );
    }
    result.push(` ${el} `);
  });
  return <>{result}</>;
};

export default EmailOrSite;

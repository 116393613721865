import { TFunction } from 'i18next';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { loginService } from '../../services/formService';
import { RootState } from '../rootReducer';

export enum ActionTypes {
  SetLoggedIn = 'SET_LOGGED_IN',

  LogoutUser = 'LOGOUT_USER',
  FetchingUserInfo = 'FETCING_USER_INFO',
}

interface SetLoggedInAction extends Action {
  type: ActionTypes.SetLoggedIn;
  payload: {
    dOB: string;
    sampleCode: string;
    token: string;
    preinformationDate?: Date;
  };
}

interface FetchingUserInfoAction extends Action {
  type: ActionTypes.FetchingUserInfo;
}

interface LogoutUserAction extends Action {
  type: ActionTypes.LogoutUser;
  payload: boolean;
}

export type UsersActionTypes =
  | LogoutUserAction
  | SetLoggedInAction
  | FetchingUserInfoAction;

type UserThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  UsersActionTypes
>;

export const LoginUser = (
  sampleCode: string,
  dOB: string,
  t: TFunction
): UserThunk => async (dispatch, getState) => {
  const state = getState();
  if (state.user.isFetchingUserInfo) {
    return;
  }

  dispatch({
    type: ActionTypes.FetchingUserInfo,
  });
  const res = await loginService({ dOB, sampleCode }, t);
  if (res) {
    const date = res.preinformationDate
      ? moment.utc(res.preinformationDate).toDate()
      : undefined;
    dispatch({
      type: ActionTypes.SetLoggedIn,
      payload: { dOB, sampleCode, token: res.token, preinformationDate: date },
    });
  } else {
    dispatch({ type: ActionTypes.LogoutUser, payload: true });
  }
};

export const LogoutUser = (): UserThunk => (dispatch) => {
  dispatch({
    type: ActionTypes.LogoutUser,
    payload: false,
  });
};

export const HOW_MUCH_TO_DRINK = 'DrinkingAmountId';
export const HOW_OFTEN_SIX = 'DrinkingSixOrMoreFrequencyId';
export const HOW_OFTEN_DRINK = 'DrinkingFrequencyId';
export const DOES_SMOKE = 'DoesSmoke';
export const SMOKED_AMOUNT = 'SmokedDailyAmount';
export const SMOKED_YEARS = 'SmokedDailyYears';
export const HAS_SMOKED = 'HasSmokedDaily';
export const MEDICINE_SENSITIVITY_WHAT = 'MedicineSensitivityDetails';
export const MEDICINE_SENSITIVITY = 'HasMedicineSensitivity';
export const MEDICINE = 'RegularMedicationIds';
export const HAS_RELATIVES_WHO = 'RelativeWithIntestinalCancerIds';
export const HAS_RELATIVES = 'HasRelativeIntestinalCancerId';
export const CHRONICLE_DISEASE = 'LongTermIllnessIds';
export const WEIGHT = 'Weight';
export const HEIGHT = 'Height';
export const SMOKED_YEARS_OVERVIEW = 'smokedYearsOverview';
export const SMOKED_AMOUNT_OVERVIEW = 'smokedAmountOverview';
export const PHONE = 'mobilePhone';

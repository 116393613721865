import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { resetPreinformationForm } from '../../state/preinfoForm/actions';
import { RootState } from '../../state/rootReducer';
import { LogoutUser } from '../../state/users/actions';
import { langPath } from '../../utils/pathUtils';

const LogoutPage: React.FC = () => {
  const { token } = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    let subscribe = true;

    if (!subscribe) return;

    dispatch(resetPreinformationForm());
    dispatch(LogoutUser());

    return () => {
      subscribe = false;
    };
  }, []);

  return !token ? <Redirect to={langPath('/')} /> : null;
};

export default LogoutPage;

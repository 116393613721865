import { TFunction } from 'i18next';
import * as Yup from 'yup';
import {
  HOW_OFTEN_DRINK,
  DOES_SMOKE,
  SMOKED_AMOUNT,
  SMOKED_YEARS,
  HAS_SMOKED,
  MEDICINE_SENSITIVITY_WHAT,
  MEDICINE_SENSITIVITY,
  MEDICINE,
  HAS_RELATIVES_WHO,
  CHRONICLE_DISEASE,
  HAS_RELATIVES,
  HEIGHT,
  HOW_MUCH_TO_DRINK,
  HOW_OFTEN_SIX,
  WEIGHT,
  PHONE,
} from '../../constants/preinformationFormKeys';

const createPreinfoConstants = (t: TFunction) => {
  return {
    validationSchema: Yup.object().shape({
      [PHONE]: Yup.string()
        .nullable()
        .matches(/^\+?[0-9]{5,20}$/gm, t('forms.mustBeNumber')),

      [HEIGHT]: Yup.number()
        .integer(t('forms.mustBeNumber'))
        .required(t('forms.requiredField'))
        .max(999, t('forms.mustBeNumberMax999'))
        .typeError(t('forms.mustBeNumber')),
      [WEIGHT]: Yup.number()
        .max(999, t('forms.mustBeNumberMax999'))
        .required(t('forms.requiredField'))
        .typeError(t('forms.mustBeNumber')),
      [CHRONICLE_DISEASE]: Yup.object()
        .shape({
          diabetes: Yup.boolean(),
          flapFailure: Yup.boolean(),
          heartFailure: Yup.boolean(),
          severLungOrHeart: Yup.boolean(),
          severDisability: Yup.boolean(),
          diseaseNoneOfAbove: Yup.boolean(),
        })
        .test(
          CHRONICLE_DISEASE,
          t('forms.requiredField'),
          (obj) =>
            obj?.diabetes ||
            obj?.flapFailure ||
            obj?.heartFailure ||
            obj?.severDisability ||
            obj?.severLungOrHeart ||
            obj?.diseaseNoneOfAbove
        ),
      [HAS_RELATIVES]: Yup.number()
        .min(1, t('forms.requiredField'))
        .required(t('forms.requiredField'))
        .nullable(),
      [HAS_RELATIVES_WHO]: Yup.object()
        .shape({
          parents: Yup.boolean(),
          siblings: Yup.boolean(),
          kids: Yup.boolean(),
          other: Yup.boolean(),
        })
        .when(HAS_RELATIVES, {
          is: 2,
          then: Yup.object()
            .shape({
              parents: Yup.boolean(),
              siblings: Yup.boolean(),
              kids: Yup.boolean(),
              otherRelative: Yup.boolean(),
            })
            .test(
              HAS_RELATIVES_WHO,
              t('forms.requiredField'),
              (obj) =>
                obj?.parents || obj?.siblings || obj?.kids || obj?.otherRelative
            ),
        }),
      [MEDICINE]: Yup.object()
        .shape({
          blood: Yup.boolean(),
          asetylSalisyd: Yup.boolean(),
          otherMedicine: Yup.boolean(),
          medicineNoneOfAbove: Yup.boolean(),
        })
        .test(
          MEDICINE,
          t('forms.requiredField'),
          (obj) =>
            obj?.blood ||
            obj?.asetylSalisyd ||
            obj?.otherMedicine ||
            obj?.medicineNoneOfAbove
        ),
      [MEDICINE_SENSITIVITY]: Yup.boolean()
        .required(t('forms.requiredField'))
        .nullable(),
      [MEDICINE_SENSITIVITY_WHAT]: Yup.string()
        .when(MEDICINE_SENSITIVITY, {
          is: true,
          then: Yup.string().required(t('forms.requiredField')),
        })
        .max(255, t('forms.mustBeNumberMax255')),
      [HAS_SMOKED]: Yup.boolean().required(t('forms.requiredField')).nullable(),
      [SMOKED_YEARS]: Yup.number().when(HAS_SMOKED, {
        is: true,
        then: Yup.number()
          .integer(t('forms.mustBeNumber'))
          .max(99, t('forms.mustBeNumberMax99'))
          .required(t('forms.requiredField'))
          .typeError(t('forms.mustBeNumber')),
      }),
      [SMOKED_AMOUNT]: Yup.number().when(HAS_SMOKED, {
        is: true,
        then: Yup.number()
          .max(99, t('forms.mustBeNumberMax99'))
          .required(t('forms.requiredField'))
          .typeError(t('forms.mustBeNumber')),
      }),
      [DOES_SMOKE]: Yup.boolean()
        .when(HAS_SMOKED, {
          is: true,
          then: Yup.boolean().required(t('forms.requiredField')),
        })
        .nullable(),
      [HOW_OFTEN_DRINK]: Yup.number()
        .oneOf([1, 2, 3, 4, 5], t('forms.requiredField'))
        .nullable(),
      [HOW_MUCH_TO_DRINK]: Yup.number()
        .when(HOW_OFTEN_DRINK, {
          is: (v) => v > 1,
          then: Yup.number().oneOf([1, 2, 3, 4, 5], t('forms.requiredField')),
        })
        .nullable(),
      [HOW_OFTEN_SIX]: Yup.number()
        .when(HOW_OFTEN_DRINK, {
          is: (v) => v > 1,
          then: Yup.number().oneOf([1, 2, 3, 4, 5], t('forms.requiredField')),
        })
        .nullable(),
    }),

    chronicDiseaseItems: [
      { label: t(`${CHRONICLE_DISEASE}.diabetes`), name: 'diabetes' },
      { label: t(`${CHRONICLE_DISEASE}.flapFailure`), name: 'flapFailure' },
      { label: t(`${CHRONICLE_DISEASE}.heartFailure`), name: 'heartFailure' },
      {
        label: t(`${CHRONICLE_DISEASE}.severLungOrHeart`),
        name: 'severLungOrHeart',
      },
      {
        label: t(`${CHRONICLE_DISEASE}.severDisability`),
        name: 'severDisability',
      },
      {
        label: t(`${CHRONICLE_DISEASE}.diseaseNoneOfAbove`),
        name: 'diseaseNoneOfAbove',
        nullify: true,
      },
    ],
    [HAS_RELATIVES]: [
      { label: t(`${HAS_RELATIVES}.1`), value: 1 },
      { label: t(`${HAS_RELATIVES}.2`), value: 2 },
      { label: t(`${HAS_RELATIVES}.3`), value: 3 },
    ],

    hasRelativesWhoItems: [
      { label: t(`${HAS_RELATIVES_WHO}.parents`), name: 'parents' },
      { label: t(`${HAS_RELATIVES_WHO}.siblings`), name: 'siblings' },
      { label: t(`${HAS_RELATIVES_WHO}.kids`), name: 'kids' },
      {
        label: t(`${HAS_RELATIVES_WHO}.otherRelative`),
        name: 'otherRelative',
      },
    ],

    medicineItems: [
      {
        label: t(`${MEDICINE}.blood`),
        name: 'blood',
      },
      {
        label: t(`${MEDICINE}.asetylSalisyd`),
        name: 'asetylSalisyd',
      },
      { label: t(`${MEDICINE}.otherMedicine`), name: 'otherMedicine' },
      {
        label: t(`${MEDICINE}.medicineNoneOfAbove`),
        name: 'medicineNoneOfAbove',
        nullify: true,
      },
    ],
    yesOrNo: [
      { label: t('common.no'), value: false },
      { label: t('common.yes'), value: true },
    ],
    yesOrNoNull: [
      { label: t('common.not'), value: 'false' },
      { label: t('common.yes'), value: 'true' },
      { label: t('common.dontKnow'), value: 'null' },
    ],
    [HOW_OFTEN_DRINK]: [
      { label: t(`${HOW_OFTEN_DRINK}.zero`), value: 1 },
      {
        label: t(`${HOW_OFTEN_DRINK}.one`),
        value: 2,
      },
      { label: t(`${HOW_OFTEN_DRINK}.two`), value: 3 },
      { label: t(`${HOW_OFTEN_DRINK}.three`), value: 4 },
      { label: t(`${HOW_OFTEN_DRINK}.four`), value: 5 },
    ],
    [HOW_MUCH_TO_DRINK]: [
      { label: t(`${HOW_MUCH_TO_DRINK}.zero`), value: 1 },
      { label: t(`${HOW_MUCH_TO_DRINK}.one`), value: 2 },
      { label: t(`${HOW_MUCH_TO_DRINK}.two`), value: 3 },
      { label: t(`${HOW_MUCH_TO_DRINK}.three`), value: 4 },
      { label: t(`${HOW_MUCH_TO_DRINK}.four`), value: 5 },
    ],
    [HOW_OFTEN_SIX]: [
      { label: t(`${HOW_OFTEN_SIX}.zero`), value: 1 },
      { label: t(`${HOW_OFTEN_SIX}.one`), value: 2 },
      { label: t(`${HOW_OFTEN_SIX}.two`), value: 3 },
      { label: t(`${HOW_OFTEN_SIX}.three`), value: 4 },
      { label: t(`${HOW_OFTEN_SIX}.four`), value: 5 },
    ],
  };
};

export default createPreinfoConstants;

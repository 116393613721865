import { Middleware } from 'redux';

const logger: Middleware = () => (next) => (action) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(action);
  }
  return next(action);
};

export default logger;

export default () => {
  const url = window.location.href;

  if (url.includes('/en')) {
    return 'en';
  }

  if (url.includes('/sv')) {
    return 'sv';
  }

  return 'fi';
};

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import defineLAng from '../../utils/defineLanguage';

const LaunguageGuard: React.FC<RouteComponentProps> = ({
  history,
}: RouteComponentProps) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    return history.listen(() => {
      const currentLan = defineLAng();
      i18n.changeLanguage(currentLan);
    });
  }, []);

  return <></>;
};

export default LaunguageGuard;

import {
  CHRONICLE_DISEASE,
  DOES_SMOKE,
  HAS_RELATIVES,
  HAS_RELATIVES_WHO,
  HAS_SMOKED,
  HEIGHT,
  HOW_MUCH_TO_DRINK,
  HOW_OFTEN_DRINK,
  HOW_OFTEN_SIX,
  MEDICINE,
  MEDICINE_SENSITIVITY,
  MEDICINE_SENSITIVITY_WHAT,
  PHONE,
  SMOKED_AMOUNT,
  SMOKED_AMOUNT_OVERVIEW,
  SMOKED_YEARS,
  SMOKED_YEARS_OVERVIEW,
  WEIGHT,
} from '../../constants/preinformationFormKeys';

const translationFi = {
  loginPage: {
    accessibilityStatement: 'Saavutettavuusseloste',
    title: 'Suolistosyövän seulonta',
    infoText:
      'Jos olet saanut kutsun suolistosyövän seulontaan HUS Diagnostiikkakeskuksesta tai ISLABista, voit täyttää Seula-palvelussa seulontaan liittyvät esitietosi tai tilata uuden näytteenottopaketin rikkoutuneen tai virheellisen tilalle.',
    requiredWithStar: '*Tähdellä merkityt ovat pakollisia',
    subTitle: 'Syötä saamasi näytetunnus ja syntymäpäivä',
    yourCode: 'Näytetunnus',
    dateOfBirth: 'Syntymäpäivä (muodossa ppkkvv, esim. 010163)',
    termsOfService: 'Tietoa palvelusta',
    submitBtn: 'Tunnistaudu',
    privacyPolicy: 'Henkilötietojen käsittely',
    registryDescriptionLink:
      'https://www.koronarokotusaika.fi/rekisteriseloste/',
  },
  orderKitPage: {
    title: 'Tilaa uusi näytteenottopaketti',
    note:
      'HUOM! Saat uuden näytteenottopaketin postitse 2-3 viikon kuluttua tilauksestasi. Odotathan rauhassa uutta näytteenottopakettia.',
    infoText:
      'Tästä voit tilata uuden näytteenottopaketin. Näytteenottopaketti vastaa sisällöltään aiemmin saamaasi kutsupakettia. Uuden näytteenottopaketin tilaaminen ei maksa sinulle mitään.',
  },
  preInformation: {
    title: 'Suolistosyövän seulontakysely',
    infoText:
      'Vastaa kaikkiin (11kpl) tällä lomakkeella oleviin kysymyksiin merkitsemällä rasti kaikkien oikeiden vastausvaihtoehtojen kohdalle tai numeroarvoilla niille varattuun tilaan.',
    guideText: '*Tähdellä merkityt ovat pakollisia',
    [HEIGHT]: 'Kuinka pitkä olet? (cm)',
    [PHONE]:
      'Puhelinnumero mahdollista yhteydenottoa varten (muodossa 0401234567 tai +358401234567)',
    [WEIGHT]: 'Kuinka paljon painat? (kg)',
    [CHRONICLE_DISEASE]: 'Onko sinulla seuraavia pitkäaikaissairauksia?',
    [HAS_RELATIVES]:
      'Onko sukulaisillasi todettu  paksusuolensyöpää tai peräsuolisyöpää?',
    [HAS_RELATIVES_WHO]: 'Kenellä sukulaisellasi?',
    [MEDICINE]:
      'Käytätkö seuraavia lääkkeitä säännöllisesti eli vähintään kerran viikossa?',
    [MEDICINE_SENSITIVITY]: 'Onko sinulla lääkeaineyliherkkyyttä?',
    [MEDICINE_SENSITIVITY_WHAT]: 'Mikä lääkeaineyliherkkyys?',
    [HAS_SMOKED]:
      'Oletko koskaan polttanut vähintään vuoden ajan päivittäin tupakkaa, sikareita tai piippua?',
    [SMOKED_YEARS]: 'yhteensä vuotta',
    [SMOKED_AMOUNT]: 'savuketta/piipullista päivässä',
    [SMOKED_YEARS_OVERVIEW]: 'Jos kyllä, kuinka monta vuotta yhteensä?',
    [SMOKED_AMOUNT_OVERVIEW]: 'Kuinka monta tupakkaa/piippua päivässä?',
    [DOES_SMOKE]:
      'Poltatko nykyisin päivittäin tupakkaa, sikareita tai piippua?',
    [HOW_OFTEN_DRINK]:
      'Kuinka usein juot alkoholijuomia? (ottakaa mukaan myös ne kerrat, jolloin nautitte vain pieniä määriä, esim. yhden oluen)',
    [HOW_MUCH_TO_DRINK]:
      'Kuinka monta annosta alkoholia yleensä juot niinä päivinä, jolloin käytätte alkoholia? (annos = pieni pullo tai tölkki olutta/siideriä/lonkeroa, lasi viiniä, snapsi)',
    [HOW_OFTEN_SIX]:
      'Kuinka usein juot vuorokauden aikana vähintään kuusi annosta alkoholia? (kuusi annosta = kuusi pientä pulloa tai tölkkiä olutta/siideriä/lonkeroa, pullo viiniä tai puoli pulloa väkevää alkoholia)',
  },
  [HOW_OFTEN_SIX]: {
    zero: 'En koskaan',
    one: 'Harvemmin kuin kerran kuussa',
    two: 'Noin kerran kuukaudessa',
    three: 'Noin kerran viikossa',
    four: 'Päivittäin tai lähes päivittäin',
  },
  [HOW_MUCH_TO_DRINK]: {
    zero: '1-2 annosta',
    one: '3-4 annosta',
    two: '5-6 annosta',
    three: '7-9 annosta',
    four: '10 annosta tai enemmän',
  },
  alcoholAmount: {
    'title-1': '1 ANNOS',
    'description-1-1': '0,33 l pullo tai tölkki III-olutta tai siideriä',
    'description-1-2': '12cl lasi mieto viiniä',
    'description-1-3': '4cl annos väkeviä',
    'title-2': '1,5 ANNOSTA',
    'description-2-1': '0,5 l pullo III-olutta tai 0,5 tuoppi',
  },
  [HOW_OFTEN_DRINK]: {
    zero: 'En käytä lainkaan alkoholijuomia',
    one: 'Noin kerran kuukaudessa tai harvemmin',
    two: '2-4 kertaa kuukaudessa',
    three: '2-3 kertaa viikossa',
    four: '4 kertaa viikossa tai useammin',
  },
  forms: {
    requiredField: 'Pakollinen kenttä',
    invalidDOB: 'Virheellinen arvo', // not used anymore, can be removed.
    maxChar6: 'Suurin merkki on 6', // not used anymore, can be removed.
    mustBeNumber: 'Tämän pitäisi olla numero',
    mustBeNumberMax999: 'Tämän pitäisi olla numero max 999',
    mustBeNumberMax99: 'Tämän pitäisi olla numero max 99',
    mustBeNumberMax255: 'Tämän pitäisi olla alle 2 55 merkkiä',
    frontPageErrorLabel: 'Tiedoissa on virheitä {{number}}:',
    frontPageErrorDescription:
      'Näytetunnus tai syntymäpäivä on virheellinen. Tarkista syöttämäsi tiedot.',
  },
  errors: {
    generalError: 'Jokin meni pieleen',
    login: 'Kirjautuminen epäonnistui!',
    formSubmit: 'Lomakkeen lähettäminen epäonnistui',
  },
  success: {
    formSubmit: 'Lomakkeen lähettäminen onnistui',
  },
  homePage: {
    title: 'Suolistosyövän seulonta',

    infoText:
      'Suolistosyöpä on yhteisnimitys paksu- ja peräsuolen syöville. Suolistosyöpä on suomalaisten kolmanneksi yleisin syöpä. Vuosittain siihen sairastuu yli 3000 henkilöä.',
    infoText1:
      'Jos olet saanut kutsun suolistosyövän seulontaan HUS Diagnostiikkakeskuksesta tai ISLABista, voit syöttää Seula-palvelussa seulontaan liittyvät esitietosi tai tilata uuden näytteenottopaketin rikkoutuneen tai virheellisen tilalle.',
  },
  links: {
    loginPage: 'Etusivu',
    logout: 'Kirjaudu ulos',
  },
  common: {
    not: 'Ei ole',
    yes: 'Kyllä',
    no: 'Ei',
    idont: 'En',
    dontKnow: 'En tiedä',
    thankyouEx: 'Kiitos!',
  },
  [HAS_RELATIVES]: {
    1: 'Ei ole',
    2: 'Kyllä',
    3: 'En tiedä',
  },
  choicebox: {
    sent: 'Lähetetty',
    title1: 'Täytä suolistosyövän seulonnan esitietolomake',
    title2: 'Tilaa uusi näytteenottopaketti',
    desc1: '',
    desc2: `Tarvitset uuden näytteenottopaketin, jos näyteputki on jollain tavoin vioittunut (esimerkiksi jos näyteputkessa ei ole nestettä) tai jos näyteputki puuttuu kokonaan saamastasi kutsupaketista.
    Tarvitset uuden näytteenottopaketin myös, jos kutsukirjeen näytetunnistetarrasta ei näe lukea H- tai IL-alkuista näytetunnistetta tai viivakoodia.
    `,
  },
  announcementsBox: {
    title: 'Ilmoitukset',
  },
  orderThanks: {
    infoText: 'Tilauksesi on vastaanotettu.',
    title: 'Kiitos tilauksesta',
  },
  buttons: {
    continue: 'Jatka',
    cancel: 'Peruuta',
    logout: 'Kirjaudu ulos',
    back: 'Muokkaa vastauksia',
    send: 'Lähetä',
    order: 'Tilaa',
    logoutWithoutSend: 'Peruuta',
  },
  overview: {
    title: 'Yhteenveto',
    infoText: 'Tarkistathan vielä että tiedot ovat oikein ennen lähettämistä',
    smokedYearsOverview: 'Vuosien määrä', // can be removed
    smokedAmountOverview: 'Savukkeiden/piippujen määrä', // can be removed
    cancelAlert:
      'Oletko varma? Kaikki antamasi tiedot menetetään ja sinut kirjataan ulos.',
  },
  [CHRONICLE_DISEASE]: {
    diabetes: 'Insuliinihoitoinen diabetes',
    flapFailure: 'Sydämen läppävika',
    heartFailure: 'Sydäninfarkti viimeisen 3 kk:n aikana',
    severLungOrHeart: 'Vaikea keuhko- tai sydänsairaus',
    severDisability: 'Vaikea liikuntakykyä rajoittava sairaus',
    diseaseNoneOfAbove: 'Ei mikään edellisistä',
  },
  [HAS_RELATIVES_WHO]: {
    parents: 'Vanhemmallani',
    siblings: 'Sisaruksellani',
    kids: 'Lapsellani',
    otherRelative:
      'Jollakin muulla/muilla sukulaisillani (esim. tädillä, serkulla)',
  },
  [MEDICINE]: {
    blood: 'Verenohennuslääke  (esim. Marevan, Pradaxa, hepariini)',
    asetylSalisyd:
      'Asetyylisalisyylivalmisteet  (esim. Asperin, Disperin, Primaspan)',
    otherMedicine: 'Muu tulehduskipulääke (esim. Burana)',
    medicineNoneOfAbove: 'Ei mikään edellisistä',
  },
  thankyou: {
    title: 'Esitiedot lähetetty',
    infoText: 'Tiedot on lähetetty onnistuneesti',
  },
  serviceInformation: {
    title: 'TIETOA PALVELUSTA',
    'subTitle-1': 'Yleistä',
    'text-1':
      'Seula seulontapalvelu on HUSin diagnostiikkakeskuksen ja ISLABin kunta-asiakkailleen tarjoama suolistosyövän seulontaa tukeva kirjaamis- ja raportointipalvelu. Palvelun avulla seulontaan osallistuvat henkilöt voivat syöttää tarvittavat esitiedot.',
    'text-2':
      'Syötetyt esitiedot tulevat näkyviin seulottavan kotikunnan (tai hyvinvointialueen) suolistosyöpäseulonnasta vastaaville ammattilaisille. Ammattilaiset kirjaavat palveluun seulontaan liittyviä tietoja, jotka seulontatutkimusten tai operaatioiden päätyttyä raportoidaan kansalliseen syöpärekisteriin (SyRe).',
    'subTitle-2': 'Sivuston toiminta',
    'text-3':
      'Seulontaan kutsuttu henkilö kirjautuu palveluun kirjeitse saamallaan näytetunnuksella sekä syntymäajalla muodossa ppkkvv. Käyttäjä syöttää tämän jälkeen palveluun vaaditut esitiedot ja tarkistuksen jälkeen tiedot tallentuvat palveluun siten, että ne ovat ammattilaisten käytettävissä.',
    'text-4': 'Tietoja ei voi syöttää kuin yhden kerran.',
    'subTitle-3': 'Evästeet',
    'text-5':
      'Sivustolla käytetään ainoastaan välttämättömiä evästeitä (cookies) käyttäjäistunnon ylläpitämiseksi. Käyttäjä ei voi estää välttämättömien evästeiden käyttöä.',
  },
  privacyPolicy: {
    title: 'HENKILÖTIETOJEN KÄSITTELY',
    'text-1':
      'Seula.hus.fi-palvelun rekisterinpitäjiä ovat kunnat/hyvinvointialueet sekä HUS-yhtymä. Palvelun tuottaja ja tietosuojalainsäädännön mukainen henkilötietojen käsittelijä on HUS-yhtymä.',
    'text-2':
      'Suolistosyöpäseulontaan liittyvien henkilötietojen käsittely perustuu lakiin seulonnoista (339/2011) sekä lain 2 § tehtyyn muutokseen (752/2021).',
    'text-3':
      'Tietosuojalainsäädännön mukaiset potilasrekisterin informoinnit ovat saatavilla kunkin rekisterinpitäjän verkkosivuilta.',
  },
  accessibilityStatement: {
    title: 'Suolistosyövän seulonta-verkkopalvelun saavutettavuus',
    'subTitle-1': 'Miten saavutettavia sivut ovat?',
    'subTitle-2': 'Huomasitko puutteita saavutettavuudessa?',
    'subTitle-3': 'Saavutettavuuden valvonta',
    'subTitle-4': 'Tarkemmat tiedot teknisestä saavutettavuudesta',
    'subTitle-5': 'Sisällöt tai toiminnot, jotka eivät vielä ole saavutettavia',
    'subTitle-6': 'Miten olemme testanneet saavutettavuutta?',
    'subTitle-7': 'Palaute ja yhteystiedot',
    'text-1':
      'HUS pyrkii takaamaan palvelun saavutettavuuden lain edellyttämien vaatimusten mukaisesti. Kaikissa palveluissa tavoitteena on Verkkosisällön saavutettavuusohjeiden (WCAG) version 2.1 määrittelemä AA-taso.',
    'text-2':
      'Tässä saavutettavuusselosteessa kerrotaan, miten Suolistosyövän seulonta -verkkopalvelussa noudatetaan lakia digitaalisten palvelujen tarjoamisesta, mitä puutteita sivuston saavutettavuudessa on ja miten voit antaa meille palautetta saavutettavuusongelmista.',
    'text-3':
      'Haluamme, että mahdollisimman moni käyttäjä pystyy käyttämään digitaalisia palvelujamme. Otamme saavutettavuuden huomioon digitaalisten palvelujemme kehityksessä.',
    'text-4':
      'Tämä verkkopalvelu täyttää suurelta osin lain vaatimat A- ja AA-tason saavutettavuuskriteerit (WCAG-kriteeristö 2.1). Verkkopalvelussa on joitakin saavutettavuuspuutteita, jotka on kuvattu tarkemmin alempana.',
    'text-5':
      'Pyrimme jatkuvasti parantamaan verkkopalvelun saavutettavuutta. Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta sivuston ylläpitäjälle.',
    'text-6':
      'Jos löydät ongelmia, joita ei ole kuvattu tällä sivulla, ilmoita niistä meille ja teemme parhaamme puutteiden korjaamiseksi. Vastaamme 14 päivän kuluessa.',
    'text-7': 'Voit antaa palautetta osoitteella seula@hus.fi.',
    'text-8':
      'Etelä-Suomen aluehallintovirasto valvoo saavutettavuusvaatimusten toteutumista. Jos et ole tyytyväinen saamaasi vastaukseen tai et saa vastausta lainkaan kahden viikon aikana, voit antaa palautteen Etelä-Suomen aluehallintovirastoon. Etelä-Suomen aluehallintoviraston sivulla kerrotaan tarkasti, miten valituksen voi tehdä ja miten asia käsitellään:',
    'text-9': `Etelä-Suomen aluehallintovirasto
      Saavutettavuuden valvonnan yksikkö 
      www.saavutettavuusvaatimukset.fi 
      saavutettavuus@avi.fi
      puhelinnumero vaihteeseen: 0295 016 000`,
    'text-10': 'Verkkopalvelu ei ole vielä kaikilta osin saavutettava. ',
    'text-11':
      '•	Verkkopalvelussa on komponentteja, joita ei tulkita oikein apuvälineteknologioiden välityksellä. Verkkopalvelun semanttinen rakenne ei ole täysin tulkittavissa apuvälineteknologioiden välityksellä. (WCAG 1.3.1, 4.1.2)',
    'text-12':
      '•	Kaikki viestit eivät ole ohjelmallisesti löydettävissä tai luettavissa. (WCAG 4.1.3)',
    'text-13': 'HUS arvioi saavutettavuutta seuraavilla tavoilla:',
    'text-14': '•	Itsearviointi',
    'text-15':
      'Otamme mielellämme vastaan ehdotuksia, huomioita, kysymyksiä ja parannusehdotuksia tähän palveluun ja sen saavutettavuuteen liittyen osoitteeseen seula@hus.fi.',
  },
  municipality: {
    askola: 'Askola',
    eksote: 'Eksote (Etelä-Karjalan sosiaali- ja terveyspiiri)',
    espoo: 'Espoo',
    hanko: 'Hanko',
    helsinki: 'Helsinki',
    hyvinkaa: 'Hyvinkää',
    inkoo: 'Inkoo',
    jarvenpaa: 'Järvenpää',
    karkkila: 'Karkkila',
    kauniainen: 'Kauniainen',
    kerava: 'Kerava',
    kirkkonummi: 'Kirkkonummi',
    kymsote:
      'Kymsote (Kymenlaakson sosiaali- ja terveyspalvelujen kuntayhtymä)',
    lapinjarvi: 'Lapinjärvi',
    lohja: 'Lohja',
    loviisa: 'Loviisa',
    mantsala: 'Mäntsälä',
    nurmijarvi: 'Nurmijärvi',
    pornainen: 'Pornainen',
    porvoo: 'Porvoo',
    raasepori: 'Raasepori',
    sipoo: 'Sipoo',
    siuntio: 'Siuntio',
    tuusula: 'Tuusula',
    vantaa: 'Vantaa',
    vihti: 'Vihti',
  },
};
export default translationFi;

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikErrors } from 'formik';
import React, { useState, useEffect } from 'react';
import styles from './checkboxGroup.module.scss';

interface IChecked {
  [key: string]: boolean;
}

interface ICheckboxItem {
  name: string;
  label: string;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  nullify?: boolean;
}

interface CheckboxGroupProps {
  name: string;
  title: string;
  items: ICheckboxItem[];
  onChange: (val: IChecked) => void;
  value: IChecked;
  required?: boolean;
  error?: string | FormikErrors<IChecked>;
  onBlur?: (e: React.FocusEvent<HTMLElement, Element>) => void;
  disabled?: boolean;
  wrapperClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  errorClassName?: string;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  name,
  title,
  items,
  onChange,
  error,
  onBlur,
  value,
  required,
  disabled,
  wrapperClassName,
  inputClassName,
  labelClassName,
  errorClassName,
}) => {
  const [checked, setChecked] = useState<IChecked>({} as IChecked);

  useEffect(() => {
    let subscribe = true;

    if (!subscribe) return;

    setChecked(value);

    return () => {
      subscribe = false;
    };
  }, []);

  useEffect(() => {
    let subscribe = true;

    if (!subscribe) return;

    onChange(checked);

    return () => {
      subscribe = false;
    };
  }, [checked]);

  const handleChange = (item: ICheckboxItem, val: boolean) => {
    if (item.nullify && val) {
      const falsyChecked: IChecked = {};
      Object.keys(value).forEach((el) => {
        falsyChecked[el] = false;
      });
      return setChecked({
        ...falsyChecked,
        [item.name]: true,
      });
    }
    if (!item.nullify) {
      const nullifytoFalse: IChecked = {};
      items.forEach((el) => {
        if (el.nullify) {
          nullifytoFalse[el.name] = false;
        }
      });
      return setChecked({ ...value, ...nullifytoFalse, [item.name]: val });
    }
    return setChecked({ ...value, [item.name]: val });
  };

  return (
    <div
      id={name}
      className={`${styles.wrapper} ${
        disabled ? styles.disabled : ''
      } ${wrapperClassName}`}
    >
      <label
        htmlFor={`${items[0].name}-${items[0].label}`}
        className={`${styles.title} ${required ? styles.required : ''} ${
          disabled ? styles.disabled : ''
        }`}
      >
        {title}
      </label>
      {items.map((item) => (
        <div
          key={item.name}
          className={`${styles.itemWrapper} ${
            disabled ? styles.disabled : ''
          } ${item.className ? item.className : ''}`}
        >
          <input
            id={`${item.name}-${item.label}`}
            type="checkbox"
            checked={value[item.name] ?? false}
            onChange={(e) => handleChange(item, e.target.checked)}
            onBlur={(e) => {
              if (onBlur) onBlur(e);
            }}
            disabled={disabled}
            name={name}
            className={`${
              item.inputClassName ? item.inputClassName : inputClassName
            }`}
          />
          <label
            htmlFor={`${item.name}-${item.label}`}
            className={`${styles.label} ${disabled ? styles.disabled : ''} ${
              item.labelClassName ? item.labelClassName : labelClassName
            }`}
          >
            {item.label}
          </label>
        </div>
      ))}
      {error !== '' ? (
        <button
          type="button"
          className={styles.errorContainer}
          data-testid={`${name}-error`}
        >
          <FontAwesomeIcon color="#E20000" icon={faTimesCircle} />

          <div className={`${styles.errors} ${errorClassName}`}>{error}</div>
        </button>
      ) : null}
    </div>
  );
};

CheckboxGroup.defaultProps = {
  onBlur: () => null,
  disabled: false,
  wrapperClassName: '',
  inputClassName: '',
  labelClassName: '',
  errorClassName: '',
  error: '',
};

export default CheckboxGroup;

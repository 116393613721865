import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FrontPageContent from '../../features/loginPageContent/loginPageContent';
import Layout from '../../features/layout/layout';
import { RootState } from '../../state/rootReducer';
import { langPath } from '../../utils/pathUtils';

const LoginPage: React.FC = () => {
  const history = useHistory();

  const {
    user: { token, preinformationDate },
    preinfoForm: { sendSucceful },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (token) {
      history.replace(langPath('/home'));
    }
  }, [token]);

  return (
    <Layout showHome={false}>
      <div className="container__row">
        <div className="container__col-12">
          <FrontPageContent />
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;

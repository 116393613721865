import {
  CHRONICLE_DISEASE,
  DOES_SMOKE,
  HAS_RELATIVES,
  HAS_RELATIVES_WHO,
  HAS_SMOKED,
  HEIGHT,
  HOW_MUCH_TO_DRINK,
  HOW_OFTEN_DRINK,
  HOW_OFTEN_SIX,
  MEDICINE,
  MEDICINE_SENSITIVITY,
  MEDICINE_SENSITIVITY_WHAT,
  PHONE,
  SMOKED_AMOUNT,
  SMOKED_AMOUNT_OVERVIEW,
  SMOKED_YEARS,
  SMOKED_YEARS_OVERVIEW,
  WEIGHT,
} from '../../constants/preinformationFormKeys';

const translationSv = {
  loginPage: {
    accessibilityStatement: 'Tillgänglighetsutlåtande',
    title: 'Screening för tarmcancer',
    infoText:
      'Om du har fått en kallelse från HUS Diagnostikcentrum eller ISLAB för screening av tarmcancer kan du fylla i nödvändiga förhandsuppgifter i tjänsten Seula eller beställa ett nytt provtagningspaket för att ersätta ett trasigt eller bristfälligt.',
    requiredWithStar: '*Fält märkta med asterisk är obligatoriska',
    subTitle: 'Ange provkod som du fått samt födelsedag',
    yourCode: 'Provkod',
    dateOfBirth: 'Födelsedag (i formen ddmmåå, t.ex. 010163)',
    termsOfService: 'Information om tjänsten',
    submitBtn: 'Logga in',
    privacyPolicy: 'Behandling av personuppgifter',
    registryDescriptionLink:
      'https://www.koronarokotusaika.fi/rekisteriseloste/',
  },
  homePage: {
    title: 'Screening för tarmcancer',
    infoText:
      'Tarmcancer omfattar både cancer i tjocktarmen och cancer i ändtarmen. Tarmcancer är den tredje vanligaste cancersjukdomen i Finland: drygt 3000 personer insjuknar i tarmcancer varje år.',
    infoText1:
      'Om du har fått en kallelse från HUS Diagnostikcentrum eller ISLAB för screening av tarmcancer kan du fylla i nödvändiga förhandsuppgifter i tjänsten Seula eller beställa ett nytt provtagningspaket för att ersätta ett trasigt eller bristfälligt.',
  },

  [HAS_RELATIVES]: {
    1: 'Nej',
    2: 'Ja',
    3: 'Jag vet inte',
  },
  errors: {
    generalError: 'Något gick fel',
    login: 'Inloggning misslyckades!',
    formSubmit: 'Formuläret kunde inte skickas in',
  },
  success: {
    formSubmit: 'Inlämning av formuläret har lyckats',
  },

  preInformation: {
    title: 'Screeningundersökning av tarmcancer',
    infoText:
      'Svara på alla frågor i den här blanketten genom att sätta kryss i rutan vid alla rätta svarsalternativ eller genom att ange ett nummervärde i respektive fält.',
    guideText: '*Fält märkta med asterisk är obligatoriska',
    [PHONE]:
      'Telefonnummer för möjlig kontakt (i formatet 0401234567 eller +358401234567)',
    [HEIGHT]: 'Hur lång är du? (cm)',
    [WEIGHT]: 'Hur mycket väger du? (kg)',
    [CHRONICLE_DISEASE]: 'Har du någon av följande långtidssjukdomar?',
    [HAS_RELATIVES]:
      'Har någon av dina släktingar diagnostiserats med cancer i tjocktarmen eller cancer i ändtarmen?',
    [HAS_RELATIVES_WHO]: 'Vilken familjemedlem?',
    [MEDICINE]:
      'Använder du följande läkemedel regelbundet dvs. minst en gång i veckan?',
    [MEDICINE_SENSITIVITY]: 'Har du läkemedelsöverkänslighet?',
    [MEDICINE_SENSITIVITY_WHAT]: 'Om ja, för vilket läkemedel?',
    [HAS_SMOKED]:
      'Har du någonsin rökt cigaretter, cigarrer eller pipa dagligen minst i ett år?',
    [SMOKED_YEARS]: 'Om ja, under hur många år totalt?',
    [SMOKED_AMOUNT]: 'Hur många cigaretter/pipor per dag?',
    [SMOKED_YEARS_OVERVIEW]: 'Om ja, hur många år totalt?',
    [SMOKED_AMOUNT_OVERVIEW]: 'Hur många cigaretter/pipor per dag?',
    [DOES_SMOKE]:
      'Röker du nuförtiden cigaretter, cigarrer eller pipa dagligen?',
    [HOW_OFTEN_DRINK]:
      'Hur ofta dricker du alkohol? (räkna också med de gånger då du dricker endast lite, t.ex. en öl)',
    [HOW_MUCH_TO_DRINK]:
      'Hur många portioner alkohol dricker du vanligtvis under de dagar då du använder alkohol? (portion = liten flaska eller burk öl/cider/long drink, ett glas vin, snaps)',
    [HOW_OFTEN_SIX]:
      'Hur ofta dricker du minst sex portioner alkohol under ett dygn? (sex portioner = sex små flaskor eller burkar öl/cider/long drink, en flaska vin eller en halv flaska starksprit)',
  },
  choicebox: {
    sent: 'Skickad',
    title1: 'Fyll i screeningens förhandsuppgifter',
    title2: 'Beställ ett nytt provtagningspaket',
    desc1: '',
    desc2: `Du behöver ett nytt provtagningspaket om provröret skadats på något sätt (till exempel om det inte finns någon vätska i provröret) eller om provröret helt saknas i paketet du fått med kallelsen. 
    Du behöver också ett nytt provtagningspaket om man inte kan se teckenserien som börjar med H eller IL, eller streckkoden på provtagningsetiketten i kallelsebrevet. 
    `,
  },
  [HOW_OFTEN_SIX]: {
    zero: 'Aldrig',
    one: 'Mindre än en gång i månaden',
    two: 'Ungefär en gång i månaden',
    three: 'Ungefär en gång i veckan',
    four: 'Dagligen eller nästan dagligen',
  },
  [HOW_MUCH_TO_DRINK]: {
    zero: '1-2 portioner',
    one: '3-4 portioner',
    two: '5-6 portioner',
    three: '7-9 portioner',
    four: '10 portionerna eller mera',
  },
  alcoholAmount: {
    'title-1': '1 PORTION',
    'description-1-1': '0,33 l flaska eller burk mellanöl eller cider',
    'description-1-2': '12 cl glas normalstarkt vin',
    'description-1-3': '4 cl portion starksprit',
    'title-2': '1,5 PORTIONER',
    'description-2-1': '0,5 l flaska mellanöl eller 0,5 l stop',
  },
  [HOW_OFTEN_DRINK]: {
    zero: 'Jag dricker inte alls alkohol',
    one: 'Ungefär en gång i månaden eller mindre',
    two: '2-4 gånger i månaden',
    three: '2-3 gånger i veckan',
    four: '4 gånger i veckan eller oftare',
  },
  forms: {
    requiredField: 'Obligatoriskt fält',
    invalidDOB: 'Virheellinen arvo', // not used. can be removed
    maxChar6: 'Suurin merkki on 6', // not used anymore, can be removed.
    mustBeNumber: 'Detta ska vara ett nummer',
    mustBeNumberMax999: 'Detta ska vara ett nummer max 999',
    mustBeNumberMax99: 'Detta ska vara ett nummer max 99',
    mustBeNumberMax255: 'Detta ska vara under 255 tecken',
    frontPageErrorLabel: 'Det finns fel i uppgifterna {{number}}:',
    frontPageErrorDescription:
      'Provkod eller födelsedag är felaktigt. Kontrollera uppgifterna du gav.',
  },
  links: {
    loginPage: 'Startsida',
    logout: 'Logga ut',
  },
  orderKitPage: {
    title: 'Beställ ett nytt provtagningspaket',
    note:
      'OBS! Du får ett nytt provtagningspaket per post 2–3 veckor efter din beställning. Var snäll och vänta på det nya provtagningspaketet.',
    infoText:
      'Här kan du beställa ett nytt provtagningspaket. Innehållet i provtagningspaketet motsvarar det du fått tidigare. Att beställa ett nytt provtagningspaket kostar ingenting.',
  },
  common: {
    not: 'Nej',
    yes: 'Ja',
    no: 'Nej',
    idont: 'Nej',
    dontKnow: 'Jag vet inte',
    thankyouEx: 'Tack!',
  },
  announcementsBox: {
    title: 'Meddelanden',
  },
  buttons: {
    continue: 'Fortsätt',
    cancel: 'Avbryt',
    logout: 'Logga ut',
    back: 'Redigera svaren',
    send: 'Skicka',
    order: 'Beställ',
    logoutWithoutSend: 'Avbryt',
  },

  orderThanks: {
    infoText: 'Din beställning har mottagits.',
    title: 'Tack för din beställning',
  },

  overview: {
    title: 'Översikt',
    infoText: 'Kontrollera att uppgifterna är korrekta innan du skickar',
    smokedYearsOverview: 'Vuosien määrä', // can be removed
    smokedAmountOverview: 'Savukkeiden/piippujen määrä', // can be removed
    cancelAlert:
      'Är du säker? Alla uppgifter som du lämnat går förlorade och du loggas ut.',
  },
  [CHRONICLE_DISEASE]: {
    diabetes: 'Diabetes som kräver insulinbehandling',
    flapFailure: 'Klaffel i hjärtat',
    heartFailure: 'Hjärtinfarkt under de senaste 3 månaderna',
    severLungOrHeart: 'Svår lung- eller hjärtsjukdom',
    severDisability: 'Svår sjukdom som begränsar rörelseförmågan',
    diseaseNoneOfAbove: 'Inget av ovanstående',
  },
  [HAS_RELATIVES_WHO]: {
    parents: 'Min förälder',
    siblings: 'Mitt syskon',
    kids: 'Mitt barn',
    otherRelative: 'Någon annan släkting (t.ex. faster moster, kusin)',
  },
  [MEDICINE]: {
    blood: 'Blodförtunningsmedel (t.ex. Marevan, Pradaxa, heparin)',
    asetylSalisyd: 'Acetylsalicylpreparat (t.ex. Aspirin, Disperin, Primaspan)',
    otherMedicine: 'Annat antiinflammatoriskt läkemedel (t.ex. Burana)',
    medicineNoneOfAbove: 'Inget av ovanstående',
  },
  thankyou: {
    title: 'Förhandsuppgifterna har skickats',
    infoText: 'Sändningen av uppgifterna lyckades',
  },
  serviceInformation: {
    title: 'INFORMATION OM TJÄNSTEN',
    'subTitle-1': 'Allmänt',
    'text-1':
      'HUS Diagnostikcentrum och ISLAB erbjuder sina kommunklienter screeningtjänsten Seula som är en registrerings- och rapporteringstjänst som stöder screening av tarmcancer. I tjänsten kan personer som deltar i screening ange nödvändiga förhandsuppgifter.',
    'text-2':
      'Förhandsuppgifterna syns för personal som ansvarar för screening av tarmcancer i den hemkommun (eller välfärdsområde) där screening pågår. Personalen registrerar uppgifter om screening i tjänsten, vilka rapporteras till det nationella cancerregistret (SyRe) när undersökningar eller operativ verksamhet i anknytning till screening har avslutats.',
    'subTitle-2': 'Verksamhet på webbplatsen',
    'text-3':
      'Person som kallas till screening loggar in i tjänsten med provkoden som kommit per brev samt födelsedatum i formen ddmmåå. Användaren anger sedan de nödvändiga förhandsuppgifterna i tjänsten och efter kontroll lagras uppgifterna i tjänsten så att de är tillgängliga för personalen.',
    'text-4': 'Uppgifterna kan endast matas in en gång.',
    'subTitle-3': 'Kakor',
    'text-5':
      'Webbplatsen använder kakor (cookies) för att upprätthålla användarsessionen. Användaren kan inte förhindra användning av nödvändiga kakor.',
  },
  accessibilityStatement: {
    title: 'Tillgängligheten till screeningtjänsten',
    'subTitle-1': 'Hur tillgängliga är sidorna?',
    'subTitle-2': 'Märkte du brister i tillgängligheten?',
    'subTitle-3': 'Tillsyn över tillgängligheten',
    'subTitle-4': 'Närmare information om teknisk tillgänglighet',
    'subTitle-5': 'Innehåll eller funktioner som ännu inte är tillgängliga',
    'subTitle-6': 'Hur har vi testat tillgängligheten?',
    'subTitle-7': 'Respons och kontaktuppgifter',

    'text-1':
      'HUS strävar att garantera tillgängligheten till tjänsten i enlighet med kraven i lagen. Målet för alla tjänster är nivå AA som fastställs i version 2.1 i Riktlinjer för tillgängligt webbinnehåll (WCAG).',
    'text-2':
      'I detta tillgänglighetsutlåtande redogörs för hur lagen om tillhandahållande av digitala tjänster iakttas i webbtjänsten seula.hus.fi, vilka brister webbplatsens tillgänglighet har och hur du kan ge oss respons på tillgänglighetsproblem.',
    'text-3':
      'Vi vill att så många användare som möjligt ska kunna använda våra digitala tjänster. Vi beaktar tillgängligheten i utvecklingen av digitala tjänster.',
    'text-4':
      'Denna webbtjänst uppfyller till stor del de lagstadgade tillgänglighetskriterierna på A- och AA-nivå (WCAG-kriterierna 2.1). I webbtjänsten finns vissa brister i tillgängligheten som beskrivs närmare nedan.',
    'text-5':
      'Vi strävar ständigt efter att förbättra webbtjänstens tillgänglighet. Om du observerar att det finns problem med tillgänglighet på vår webbplats, ge först respons till den som upprätthåller webbplatsen.',
    'text-6':
      'Om du hittar problem som inte beskrivs på den här sidan, meddela oss om dem så gör vi vårt bästa för att åtgärda bristerna. Vi svarar inom 14 dagar.',
    'text-7': 'Du kan lämna respons på adressen seula@hus.fi.',
    'text-8':
      'Regionförvaltningsverket i Södra Finland övervakar att tillgänglighetskraven uppfylls. Om du inte är nöjd med svaret som du har fått eller om du inte får något svar alls inom två veckor kan du ge respons till Regionförvaltningsverket i Södra Finland. På webbplatsen för Regionförvaltningsverket i Södra Finlands berättas noggrant hur man kan anföra besvär och hur ärendet behandlas:',
    'text-9': `Regionförvaltningsverket i Södra Finland, Enheten för tillgänglighetstillsyn 
      www.tillgänglighetskrav.fi 
      webbtillganglighet@rfv.fi 
      telefonnummer till växeln: 0295 016000`,
    'text-10': 'Webbtjänsten är ännu inte till alla delar tillgänglig. ',
    'text-11':
      '•	Webbtjänsten innehåller komponenter som inte kan tolkas korrekt med hjälp av hjälpmedelsteknologier. Webbtjänstens semantiska struktur kan inte tolkas helt med hjälp av hjälpmedelsteknologier. (WCAG 1.3.1, 4.1.2)',
    'text-12':
      '•	Alla meddelanden kan inte hittas eller läsas automatiskt. (WCAG 4.1.3)',
    'text-13': 'HUS bedömer tillgängligheten på följande sätt:',
    'text-14': '•	Självbedömning',
    'text-15':
      'Vi tar gärna emot förslag, kommentarer, frågor och förbättringsförslag om denna tjänst och dess tillgänglighet på adressen seula@hus.fi',
  },
  privacyPolicy: {
    title: 'BEHANDLING AV PERSONUPPGIFTER',
    'text-1':
      'Kommunerna/välfärdsområdena samt HUS-sammanslutningen är personuppgiftsansvariga för seula.hus.fi-tjänsten. Tjänsteleverantör och personuppgiftsbiträde i enlighet med dataskyddslagstiftningen är HUS-sammanslutningen.',
    'text-2':
      'Behandlingen av personuppgifter om screening av tarmcancer grundas på lagen om screening (339/2011) och på ändringen av lagens 2 § (752/2021). Personuppgiftsansvarig är den kommun eller samkommun som ansvarar för att genomföra screening i det aktuella fallet.',
    'text-3':
      'Information om patientregistret i enlighet med dataskyddslagstiftningen finns på varje personuppgiftsansvarigs webbplats.',
  },
  municipality: {
    askola: 'Askola',
    eksote: 'Eksote-området',
    espoo: 'Esbo',
    hanko: 'Hangö',
    helsinki: 'Helsingfors',
    hyvinkaa: 'Hyvinge',
    inkoo: 'Ingå',
    jarvenpaa: 'Träskända',
    karkkila: 'Högfors',
    kauniainen: 'Grankulla',
    kerava: 'Kervo',
    kirkkonummi: 'Kyrkslätt',
    kymsote: 'Kymsote-området',
    lapinjarvi: 'Lappträsk',
    lohja: 'Lojo',
    loviisa: 'Lovisa',
    mantsala: 'Mäntsälä',
    nurmijarvi: 'Nurmijärvi',
    pornainen: 'Borgnäs',
    porvoo: 'Borgå',
    raasepori: 'Raseborg',
    sipoo: 'Sibbo',
    siuntio: 'Sjundeå',
    tuusula: 'Tusby',
    vantaa: 'Vanda',
    vihti: 'Vichtis',
  },
};
export default translationSv;

import axios from 'axios';
import { TFunction } from 'i18next';
import { toast } from 'react-toastify';
import { RootState } from '../state/rootReducer';
import { User } from '../state/users/state';
import convertForm from './preinformation';

export const submitFormService = async (
  rootState: RootState,
  t: TFunction
): Promise<boolean> => {
  const baseUrl = process.env.REACT_APP_API_URL;

  try {
    await axios.post(
      `${baseUrl}/preinformation`,
      convertForm(rootState.preinfoForm),
      {
        headers: {
          Authorization: `Bearer ${rootState.user.token}`,
        },
      }
    );
    toast.success(t('success.formSubmit'));
    return true;
  } catch (e) {
    toast.error(t('errors.formSubmit'));
    return false;
  }
};
export const loginService = async (
  user: User,
  t: TFunction
): Promise<{ token: string; preinformationDate: string } | null> => {
  try {
    const baseUrl = process.env.REACT_APP_API_URL;
    const response = await axios.post(`${baseUrl}/user/login`, {
      dOB: user.dOB,
      sampleCode: user.sampleCode,
    });
    return response.data;
  } catch (e) {
    toast.error(t('errors.login'));
    return null;
  }
};

export const orderKitService = async (
  rootState: RootState,
  t: TFunction
): Promise<boolean> => {
  try {
    const baseUrl = process.env.REACT_APP_API_URL;
    await axios.post(
      `${baseUrl}/samplekitorder`,
      {},
      {
        headers: {
          Authorization: `Bearer ${rootState.user.token}`,
        },
      }
    );
    return true;
  } catch (e) {
    toast.error(t('errors.generalError'));
    return false;
  }
};

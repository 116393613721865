import { IParagraph } from '../models/IParagraph';

type Pages = 'ServiceInformation' | 'accessibilityStatement' | 'privacyPolicy';
const list: { [keyof in Pages]: IParagraph[] } = {
  ServiceInformation: [
    {
      pTitle: 'subTitle-1',
      paragraph: ['text-1', 'text-2'],
    },
    {
      pTitle: 'subTitle-2',
      paragraph: ['text-3', 'text-4'],
    },
    {
      pTitle: 'subTitle-3',
      paragraph: ['text-5'],
    },
  ],
  privacyPolicy: [{ pTitle: '', paragraph: ['text-1', 'text-2', 'text-3'] }],
  accessibilityStatement: [
    { pTitle: '', paragraph: ['text-1', 'text-2', 'text-3'] },
    { pTitle: 'subTitle-1', paragraph: ['text-4'] },
    { pTitle: 'subTitle-2', paragraph: ['text-5', 'text-6', 'text-7'] },
    { pTitle: 'subTitle-3', paragraph: ['text-8', 'text-9'] },
    { pTitle: 'subTitle-4', paragraph: ['text-10'] },
    { pTitle: 'subTitle-5', paragraph: ['text-11', 'text-12'] },
    { pTitle: 'subTitle-6', paragraph: ['text-13', 'text-14'] },
    { pTitle: 'subTitle-7', paragraph: ['text-15'] },
  ],
};

export default list;

import { Button } from '@husepidemic/epidemicdesign/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReminderBox from '../../components/reminerBox/reminderBox';
import { OrderKit } from '../../state/preinfoForm/actions';
import { RootState } from '../../state/rootReducer';
import { langPath } from '../../utils/pathUtils';

const OrderKitContent: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reoderdKit, isSending } = useSelector(
    (state: RootState) => state.preinfoForm
  );
  return (
    <>
      {!reoderdKit ? (
        <div>
          <h1>{t('orderKitPage.title')}</h1>
          <p className="info-text">{t('orderKitPage.infoText')}</p>

          <ReminderBox>
            {' '}
            <p className="info-text">{t('orderKitPage.note')}</p>
          </ReminderBox>
          <div className="buttons-wrapper container__row">
            <Button
              className="confirm-btn container__col-12 container__col-sm-5"
              onClick={() => dispatch(OrderKit(t))}
              disabled={isSending}
            >
              {t('buttons.order')}
            </Button>
            <div className="container__col-sm-2">&nbsp;</div>
            <Button
              disabled={isSending}
              className="cancel-btn container__col-12 container__col-sm-5"
              onClick={() => history.replace(langPath('/home'))}
            >
              {t('buttons.cancel')}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <h1>{t('orderThanks.title')}</h1>
          <p className="info-text">{t('orderThanks.infoText')}</p>
          <div className="buttons-wrapper container__row">
            <Button
              className="confirm-btn container__col-12"
              onClick={() => history.replace(langPath('/logout'))}
            >
              {t('buttons.logout')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderKitContent;

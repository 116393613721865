import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useField } from 'formik';
import React from 'react';
import styles from './radioGroup.module.scss';

interface IRadioItem {
  label: string;
  value: any;
}

interface IRadioGroup {
  name: string;
  label: string;
  items: IRadioItem[];
  className?: string;
  required?: boolean;
  itemLabelClass?: string;
  itemClass?: string;
  onChange?: (val: string) => void;
  disabled?: boolean;
  labelClass?: string;
  error?: string;
  wrapperClassName?: string;
}

const RadioGroup: React.FC<IRadioGroup> = ({
  name,
  items,
  label,
  className,
  required,
  itemLabelClass,
  itemClass,
  onChange,
  disabled,
  labelClass,
  error,
  wrapperClassName,
}) => {
  const [field] = useField(name);

  return (
    <div className={wrapperClassName}>
      <fieldset className={`${className}`} disabled={disabled}>
        <legend
          className={`${styles.label} ${
            required ? styles.required : ''
          } ${labelClass}`}
        >
          <span>{label}</span>
        </legend>
        {items.map((item) => (
          <div key={item.label} className={styles.fieldWrapper}>
            <Field
              data-test-id={`${name}-${item.label}`}
              type="radio"
              name={name}
              id={`${name}-${item.label}`}
              required={required}
              onChange={(e: any) => {
                if (onChange) return onChange(e.target.value);
                field.onChange(e);
              }}
              value={item.value}
              disabled={disabled}
              aria-labelledby={item.label}
            />
            <label
              key={item.label}
              className={`${styles.item} ${itemClass}`}
              htmlFor={`${name}-${item.label}`}
            >
              <span className={`${styles.itemLabel} ${itemLabelClass}`}>
                {item.label}
              </span>
            </label>
          </div>
        ))}
      </fieldset>
      {error && (
        <button
          type="button"
          className={styles.errorContainer}
          data-testid={`${name}-error`}
        >
          <FontAwesomeIcon color="#E20000" icon={faTimesCircle} />
          <div className={styles.errors}>{error}</div>
        </button>
      )}
    </div>
  );
};

RadioGroup.defaultProps = {
  className: '',
  required: false,
  itemLabelClass: '',
  itemClass: '',
  onChange: undefined,
  disabled: false,
  labelClass: '',
  error: '',
  wrapperClassName: '',
};

export default RadioGroup;

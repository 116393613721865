import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import React from 'react';
import './inputWithTitle.scss';

interface IInputWithTitleProps {
  name: string;
  label: string;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  labelClassName?: string;
  required?: boolean;
  maxLength?: number;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  errorClassName?: string;
  hideErros?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: any | null;
}

const InputWithTitle: React.FC<IInputWithTitleProps> = ({
  name,
  label,
  inputClassName,
  disabled,
  className,
  labelClassName,
  required,
  maxLength,
  inputMode,
  errorClassName,
  hideErros,
  onChange,
  onBlur,
  value,
}) => {
  const [field, meta] = useField(name);

  return (
    <div
      data-testid={`${name}-wrapper`}
      className={`hus-epidemic-input-with-title-wrapper ${className ?? ''}`}
    >
      <label
        data-testid={`${name}-label`}
        className={`hus-epidemic-input-with-title-label ${
          labelClassName ?? ''
        } ${required ? 'hus-epidemic-input-with-title-required' : ''}`}
        htmlFor={name}
      >
        {label}
      </label>
      <input
        id={name}
        data-testid={`${name}-input`}
        className={`hus-epidemic-input-with-title-input ${
          inputClassName ?? ''
        }`}
        type="text"
        checked={field.checked}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          } else {
            field.onChange(e);
          }
        }}
        value={value ?? `${field.value}`}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          } else {
            field.onBlur(e);
          }
        }}
        name={name}
        disabled={disabled}
        maxLength={maxLength}
        inputMode={inputMode}
      />
      {meta.error && !hideErros ? (
        <button
          type="button"
          data-testid={`${name}-error`}
          className={`hus-epidemic-input-with-title-error ${errorClassName}`}
        >
          {' '}
          <FontAwesomeIcon color="#E20000" icon={faTimesCircle} />
          {meta.error}
        </button>
      ) : null}
    </div>
  );
};

InputWithTitle.defaultProps = {
  className: '',
  labelClassName: '',
  inputClassName: '',
  disabled: false,
  maxLength: undefined,
  inputMode: undefined,
  errorClassName: '',
  hideErros: false,
  onChange: undefined,
  onBlur: undefined,
  value: null,
};

export default InputWithTitle;

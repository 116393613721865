import React, { useEffect } from 'react';
import Layout from '../../features/layout/layout';
import OverviewPageContent from '../../features/overviewPageContent/overviewPageContent';
import { preventRefresh } from '../../utils/preventRefresh';

const OverviewPage: React.FC = () => {
  preventRefresh();
  return (
    <Layout>
      <div className="container__row">
        <div className="container__col-12">
          <OverviewPageContent />
        </div>
      </div>
    </Layout>
  );
};

export default OverviewPage;

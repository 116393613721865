import { TFunction } from 'i18next';
import { toast } from 'react-toastify';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IPreinformationForm } from '../../models/IPreinformationForm';
import { orderKitService, submitFormService } from '../../services/formService';
import { RootState } from '../rootReducer';

export enum ActionTypes {
  SetFormValues = 'SET_FORM_VALUES',
  SendFormValue = 'SEND_FORM_VALUES',
  SendFormValuesSuccessfull = 'SEND_FORM_VALUES_SUCCESSFULL',
  SendFormValuesFailed = 'SEND_FORM_VALUES_Failed',
  ResetPreinformationForm = 'RESET_FORM_VALUES',
  SetOrderdKit = 'SET_ORDERED_KIT',
  SetSending = 'SET_Sending',
}

interface SetFormValuesAction extends Action {
  type: ActionTypes.SetFormValues;
  payload: IPreinformationForm;
}
interface SetSending extends Action {
  type: ActionTypes.SetSending;
  payload: boolean;
}

interface SendFormValuesAction extends Action {
  type: ActionTypes.SendFormValue;
}

interface SendFormValuesSuccessfullAction extends Action {
  type: ActionTypes.SendFormValuesSuccessfull;
}
interface SendFormValuesFaildAction extends Action {
  type: ActionTypes.SendFormValuesFailed;
}

interface OrderKitAction extends Action {
  type: ActionTypes.SetOrderdKit;
  payLoad: boolean;
}

interface ResetPreinformationFormAction extends Action {
  type: ActionTypes.ResetPreinformationForm;
}

export const setPreinfoFormValues = (values: IPreinformationForm) => ({
  type: ActionTypes.SetFormValues,
  payload: values,
});

export const resetPreinformationForm = () => ({
  type: ActionTypes.ResetPreinformationForm,
});

export type PreinfoFormActions =
  | SetFormValuesAction
  | SendFormValuesAction
  | OrderKitAction
  | SetSending
  | SendFormValuesFaildAction
  | SendFormValuesSuccessfullAction
  | ResetPreinformationFormAction;

type PreinformationThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  PreinfoFormActions
>;

export const SendFormData = (t: TFunction): PreinformationThunk => async (
  dispatch,
  getState
) => {
  dispatch({ type: ActionTypes.SendFormValue });

  const res = await submitFormService(getState(), t);
  if (res) {
    dispatch({ type: ActionTypes.SendFormValuesSuccessfull });
  } else {
    dispatch({ type: ActionTypes.SendFormValuesFailed });
  }
};

export const OrderKit = (t: TFunction): PreinformationThunk => async (
  dispatch,
  getState
) => {
  dispatch({ type: ActionTypes.SetSending, payload: true });
  const res = await orderKitService(getState(), t);
  dispatch({ type: ActionTypes.SetOrderdKit, payLoad: res });
  dispatch({ type: ActionTypes.SetSending, payload: false });
};

import {
  HOW_OFTEN_DRINK,
  DOES_SMOKE,
  SMOKED_AMOUNT,
  SMOKED_YEARS,
  HAS_SMOKED,
  MEDICINE_SENSITIVITY_WHAT,
  MEDICINE_SENSITIVITY,
  MEDICINE,
  HAS_RELATIVES_WHO,
  CHRONICLE_DISEASE,
  HAS_RELATIVES,
  HEIGHT,
  HOW_MUCH_TO_DRINK,
  HOW_OFTEN_SIX,
  WEIGHT,
  PHONE,
} from '../../constants/preinformationFormKeys';
import { PreinfoFormActions, ActionTypes } from './actions';
import { IPreinformationFormState } from './state';

export const initialPreinformationFormState: IPreinformationFormState = {
  [PHONE]: '',
  [HEIGHT]: '',
  [WEIGHT]: '',
  [CHRONICLE_DISEASE]: {
    diabetes: false,
    flapFailure: false,
    heartFailure: false,
    severLungOrHeart: false,
    severDisability: false,
    diseaseNoneOfAbove: false,
  },
  [HAS_RELATIVES]: 0,
  [HAS_RELATIVES_WHO]: {
    parents: false,
    siblings: false,
    kids: false,
    otherRelative: false,
  },
  [MEDICINE]: {
    blood: false,
    asetylSalisyd: false,
    otherMedicine: false,
    medicineNoneOfAbove: false,
  },
  [MEDICINE_SENSITIVITY]: null,
  [MEDICINE_SENSITIVITY_WHAT]: '',
  [HAS_SMOKED]: null,
  [SMOKED_YEARS]: '',
  [SMOKED_AMOUNT]: '',
  [DOES_SMOKE]: null,
  [HOW_OFTEN_DRINK]: null,
  [HOW_MUCH_TO_DRINK]: null,
  [HOW_OFTEN_SIX]: null,
  isSending: false,
  sendSucceful: false,
  reoderdKit: false,
};

export const PreInfoFormReducer = (
  state: IPreinformationFormState = initialPreinformationFormState,
  action: PreinfoFormActions
): IPreinformationFormState => {
  switch (action.type) {
    case ActionTypes.SetFormValues:
      return { ...state, ...action.payload };
    case ActionTypes.SendFormValue:
      return { ...state, isSending: true };
    case ActionTypes.SendFormValuesSuccessfull:
      return { ...state, isSending: false, sendSucceful: true };
    case ActionTypes.SendFormValuesFailed:
      return { ...state, isSending: false, sendSucceful: false };
    case ActionTypes.ResetPreinformationForm:
      return {
        ...initialPreinformationFormState,
        isSending: state.isSending,
        sendSucceful: state.sendSucceful,
      };
    case ActionTypes.SetSending:
      return {
        ...state,
        isSending: action.payload,
      };
    case ActionTypes.SetOrderdKit:
      return {
        ...state,
        reoderdKit: action.payLoad,
      };
    default:
      return state;
  }
};

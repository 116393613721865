import React from 'react';
import { toast } from 'react-toastify';
import RouterComponent from './routes';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  toast.configure({
    autoClose: 4000,
    draggable: false,
  });
  return (
    <div className="App">
      <RouterComponent />
    </div>
  );
};

export default App;

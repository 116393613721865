import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RootState } from '../../state/rootReducer';
import { langPath } from '../../utils/pathUtils';

const AuthGuard: React.FC<{
  exact: true;
  path: string;
  component: React.FC<any>;
}> = ({ component: Component, ...rest }) => {
  const { token } = useSelector((state: RootState) => state.user);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          return <Component {...props} />;
        }
        if (!token) {
          return <Redirect to={langPath('/')} />;
        }
      }}
    />
  );
};

export default AuthGuard;

import React, { useEffect } from 'react';
import { Button } from '@husepidemic/epidemicdesign/button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  SendFormData,
  resetPreinformationForm,
} from '../../state/preinfoForm/actions';
import { RootState } from '../../state/rootReducer';
import {
  defineValue,
  filterLabels,
  findLabel,
  fixLabel,
} from './overviewPageContentHelper';
import { langPath } from '../../utils/pathUtils';
import styles from './overviewPageContent.module.scss';

const OverviewPageContent: React.FC = () => {
  const dispatch = useDispatch();
  const preinfoForm = useSelector((state: RootState) => state.preinfoForm);
  const { preinfoFormLabels } = useSelector(
    (state: RootState) => state.settings
  );
  const { isSending, sendSucceful } = useSelector(
    (state: RootState) => state.preinfoForm
  );

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (sendSucceful) {
      history.replace(langPath('/thanks'));
    }
  }, [isSending]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async () => {
    dispatch(SendFormData(t));
  };

  return (
    <div>
      <h1>{t('overview.title')}</h1>
      <p className="info-text">{t('overview.infoText')}</p>
      {Object.keys(preinfoForm)
        .filter((x) => filterLabels(x, preinfoForm))
        .map(fixLabel)
        .map((formItem: string) => {
          return (
            <div key={formItem} className={styles.valueWrapper}>
              <span className={styles.valueTitle}>
                {t(findLabel(formItem, preinfoFormLabels))}
              </span>
              <span className={styles.value}>
                {defineValue(formItem, preinfoForm, t)}
              </span>
            </div>
          );
        })}
      <div className="buttons-wrapper container__row">
        <Button
          type="button"
          onClick={handleSubmit}
          className="confirm-btn container__col-12 container__col-lg-3"
          label={t('buttons.send')}
          disabled={isSending}
        />
        <div className="container__col-lg-1">&nbsp;</div>
        <Button
          label={t('buttons.back')}
          className="cancel-btn container__col-12 container__col-lg-4"
          onClick={() => history.push(langPath('/prerequisites'))}
          disabled={isSending}
        />
        <div className="container__col-lg-1">&nbsp;</div>
        <Button
          label={t('buttons.logoutWithoutSend')}
          className={`${styles.logoutBtn} cancel-btn container__col-12 container__col-lg-3`}
          onClick={() => {
            dispatch(resetPreinformationForm());
            history.replace(langPath('/home'));
          }}
          disabled={isSending}
        />
      </div>
    </div>
  );
};

export default OverviewPageContent;

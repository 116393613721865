import { Button } from '@husepidemic/epidemicdesign/button';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetPreinformationForm } from '../../state/preinfoForm/actions';
import { langPath } from '../../utils/pathUtils';
import { RootState } from '../../state/rootReducer';
import styles from './thankyouPageContent.module.scss';

const ThankyouPageContent: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { preinformationDate } = useSelector((state: RootState) => state.user);
  const submissionDate = preinformationDate
    ? moment(preinformationDate)
    : moment();

  useEffect(() => {
    let subscribe = true;
    if (!subscribe) return;

    dispatch(resetPreinformationForm());

    return () => {
      subscribe = false;
      history.push('');
    };
  }, []);
  return (
    <div className={styles.contentWrapper}>
      <h1 className={styles.title}>{t('thankyou.title')}</h1>
      <p className={`info-text ${styles.infoText}`}>
        {t('thankyou.infoText')} {submissionDate.format('D.M.yyyy')}{' '}
        {t('common.thankyouEx')}
      </p>
      <div className="buttons">
        <Button
          className="confirm-btn"
          onClick={() => history.replace(langPath('/logout'))}
        >
          {t('buttons.logout')}
        </Button>
      </div>
    </div>
  );
};

export default ThankyouPageContent;

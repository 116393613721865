import React from 'react';
import Layout from '../../features/layout/layout';
import OrderKitContent from '../../features/orderKitContents/orderKitContent';

const OrderKitPage: React.FC = () => {
  return (
    <Layout>
      <OrderKitContent />
    </Layout>
  );
};

export default OrderKitPage;

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './errorListing.module.scss';

interface IErrorListingProps {
  label: string;
  items: string[];
}

const ErrorListing: React.FC<IErrorListingProps> = ({ label, items }) => {
  return (
    <div className={styles.errorMessage}>
      <FontAwesomeIcon
        className={styles.errorIcon}
        color="#E20000"
        icon={faTimesCircle}
      />
      <div className={styles.errors}>
        <div>{label}</div>
        <ul>
          {items.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ErrorListing;

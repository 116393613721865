import React from 'react';
import Layout from '../../features/layout/layout';
import PrivacyPolicyContent from '../../features/privacyPolicyContent/privacyPolicyContent';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Layout showHome={false}>
      <div className="container__row">
        <PrivacyPolicyContent />
      </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;

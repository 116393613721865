import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FocusScrollError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const names = Object.keys(errors);
      if (names.length > 0) {
        const selector = '[name=Error-List]';
        const errorElement = document.querySelector(selector) as HTMLElement;
        if (errorElement) {
          window.focus();
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          setTimeout(() => {
            errorElement.focus({ preventScroll: true });
          }, 100);
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

export default FocusScrollError;

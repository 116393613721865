import { Button } from '@husepidemic/epidemicdesign/button';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ChoiceBox from '../../components/choiceBox/choiceBox';
import TermService from '../../components/termService/termService';
import { RootState } from '../../state/rootReducer';
import { langPath } from '../../utils/pathUtils';
import styles from './homePagecontent.module.scss';

const HomePagecontent: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    user: { preinformationDate },
    preinfoForm: { sendSucceful },
  } = useSelector((state: RootState) => state);

  let submissionDate;

  if (preinformationDate)
    submissionDate = `${t('choicebox.sent')} ${moment(
      preinformationDate
    ).format('D.M.yyyy')}`;
  if (sendSucceful)
    submissionDate = `${t('choicebox.sent')} ${moment().format('D-M-YYYY')}`;

  return (
    <div>
      <h1>{t('homePage.title')}</h1>
      <p className={styles.infoText}>{t('homePage.infoText')}</p>
      <p className={styles.infoText}>{t('homePage.infoText1')}</p>

      <ChoiceBox
        enable={!(preinformationDate || sendSucceful)}
        // use for enabling form en debugging
        // enable={true}
        onclick={() => history.push(langPath('/prerequisites'))}
        title={t('choicebox.title1')}
        desc={t('choicebox.desc1')}
        aditionalInfo={submissionDate}
      />
      <ChoiceBox
        enable={true}
        onclick={() => history.push(langPath('/orderkit'))}
        title={t('choicebox.title2')}
        desc={t('choicebox.desc2')}
      />

      <div className="buttons-wrapper">
        <Button
          className={`confirm-btn ${styles.logOut}`}
          onClick={() => history.replace(langPath('/logout'))}
        >
          {t('buttons.logout')}
        </Button>
      </div>
      <TermService />
    </div>
  );
};

export default HomePagecontent;

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikErrors, useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPreinformationFormState } from '../../state/preinfoForm/state';
import styles from './errorsList.module.css';

interface IErrorsList<T> {
  errors: FormikErrors<T>;
  src: string;
}

const ErrorsList = <T extends object>({ src, errors }: IErrorsList<T>) => {
  const { t } = useTranslation();
  return (
    <>
      {errors && Object.keys(errors).length ? (
        <>
          <FontAwesomeIcon
            className={styles.errorIcon}
            color="#E20000"
            icon={faTimesCircle}
          />
          <button type="button" name="Error-List" className={styles.errorLabel}>
            {t('forms.frontPageErrorLabel', {
              number: Object.keys(errors).length,
            })}
          </button>
          {(Object.keys(errors) as Array<keyof typeof errors>).map((el) => {
            if (!el || typeof el !== 'string') return;
            return (
              <li className={styles.list} key={el}>
                <button
                  name={`error-${el}`}
                  className={styles.button}
                  type="button"
                  onClick={() => {
                    const selector = `[name=${el}]`;
                    const errorElement = document.querySelector(
                      selector
                    ) as HTMLElement;
                    if (errorElement) {
                      window.focus();
                      errorElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      });

                      setTimeout(() => {
                        errorElement.focus({ preventScroll: true });
                      }, 100);
                    }
                  }}
                >{`${t(`${src}.${el}`)} ${errors[el]}`}</button>
              </li>
            );
          })}
        </>
      ) : null}
    </>
  );
};

export default ErrorsList;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IAnnouncement } from '../../models/IAnnouncement';
import { FetchAnnouncementData } from '../../state/announcements/actions';
import { RootState } from '../../state/rootReducer';
import defineLanguage from '../../utils/defineLanguage';
import styles from './announcementBox.module.scss';

const AnnouncementBox: React.FC<{
  announcements: IAnnouncement[];
  warning: boolean;
}> = ({ announcements, warning }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.box} ${
        warning ? styles.warning : styles.info
      } container__col-12}`}
    >
      <div className={`${styles.title} container__col-12`}>
        <span className={styles.titleContent}>
          {t('announcementsBox.title')}
        </span>
      </div>
      <div className={`${styles.content} container__col-12`}>
        {announcements.map((a) => {
          return (
            <div className={styles.contentContainer} key={a.title}>
              <div className={styles.announcementTitle}>{a.title}</div>
              <div className={styles.announcementBody}>{a.text}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const AnnouncementBoxes: React.FC = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state: RootState) => state.announcements);

  useEffect(() => {
    dispatch(FetchAnnouncementData());
  }, [defineLanguage()]);

  return (
    <>
      {data.filter((el) => el.warning).length !== 0 && (
        <AnnouncementBox
          announcements={data.filter((el) => el.warning)}
          warning={true}
        />
      )}
      {data.filter((el) => !el.warning).length !== 0 && (
        <AnnouncementBox
          announcements={data.filter((el) => !el.warning)}
          warning={false}
        />
      )}
    </>
  );
};

export default AnnouncementBoxes;

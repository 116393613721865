import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './reminderBox.module.scss';

interface IReminderBox {
  children: React.ReactNode;
  className?: string;
  textClass?: string;
  iconClass?: string;
}

const ReminderBox: React.FC<IReminderBox> = ({
  children,
  className,
  textClass,
  iconClass,
}) => {
  return (
    <div
      className={`${className} ${styles.reminderTextWrapper} container__col-12`}
    >
      <FontAwesomeIcon
        size="2x"
        className={`${iconClass} ${styles.remiderTextIcon}`}
        icon={faExclamationCircle}
      />
      <span className={`${textClass} ${styles.remiderText}`}>{children}</span>
    </div>
  );
};

ReminderBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  textClass: PropTypes.string,
  iconClass: PropTypes.string,
};

ReminderBox.defaultProps = {
  className: '',
  textClass: '',
  iconClass: '',
};

export default ReminderBox;

import React from 'react';
import Header from '../header/header';
import styles from './layout.module.scss';

interface ILayoutProps {
  showHome?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({ children, showHome }) => {
  return (
    <div className="container--fluid">
      <Header showHome={showHome} />
      <div className={`container__row ${styles.content}`}>{children}</div>
    </div>
  );
};

export default Layout;

import {
  CHRONICLE_DISEASE,
  DOES_SMOKE,
  HAS_RELATIVES,
  HAS_RELATIVES_WHO,
  HAS_SMOKED,
  HEIGHT,
  HOW_MUCH_TO_DRINK,
  HOW_OFTEN_DRINK,
  HOW_OFTEN_SIX,
  MEDICINE,
  MEDICINE_SENSITIVITY,
  MEDICINE_SENSITIVITY_WHAT,
  PHONE,
  SMOKED_AMOUNT,
  SMOKED_AMOUNT_OVERVIEW,
  SMOKED_YEARS,
  SMOKED_YEARS_OVERVIEW,
  WEIGHT,
} from '../../constants/preinformationFormKeys';

const translationEn = {
  loginPage: {
    accessibilityStatement: 'Accessibility statement',
    title: 'Screening for colorectal cancer',
    infoText:
      'If you have received an invite to colorectal cancer screening from HUS Diagnostic Center or ISLAB, you can use this Seula service to enter your health information related to the screening or order a new sampling kit to replace a broken or faulty one.',
    requiredWithStar: '*Fields marked with an asterisk are required',
    subTitle:
      'Please enter the sample ID, which is mentioned on the home sample kit, together with your date of birth.',
    yourCode: 'Sample ID',
    dateOfBirth: 'Date of birth (format ddmmyy, e.g. 010163)',
    termsOfService: 'Information about the service',
    submitBtn: 'Sign in',
    privacyPolicy: 'Processing of personal data',
    registryDescriptionLink:
      'https://www.koronarokotusaika.fi/rekisteriseloste/',
  },

  [HAS_RELATIVES]: {
    1: 'No',
    2: 'Yes',
    3: "I don't know",
  },

  preInformation: {
    title: 'Colorectal cancer screening - Preliminary information',
    infoText:
      'Please, answer all of the questions on this form by ticking the box for all correct response options or using numeric values in the space provided.',
    guideText: '*Fields marked with an asterisk are required',
    [HEIGHT]: 'How tall are you? (cm)',
    [PHONE]:
      'Phone number for possible contact (in the format 0401234567 or +358401234567)',
    [WEIGHT]: 'What is your weight? (kg)',
    [CHRONICLE_DISEASE]:
      'Do you have any of the following long-term illnesses?',
    [HAS_RELATIVES]:
      'Have your relatives been diagnosed with colon cancer or rectal cancer?',
    [HAS_RELATIVES_WHO]: 'Which relative?',
    [MEDICINE]:
      'Do you take any of the following drugs regularly, at least once a week?',
    [MEDICINE_SENSITIVITY]: 'Are you hypersensitive to a drug?',
    [MEDICINE_SENSITIVITY_WHAT]: 'If yes, to which drug?',
    [HAS_SMOKED]:
      'Have you ever smoked cigarettes, cigars, or a pipe daily for at least a year?',
    [SMOKED_YEARS]: 'If yes, for how many years in total?',
    [SMOKED_AMOUNT]: 'How many cigarettes/pipefuls a day?',
    [SMOKED_YEARS_OVERVIEW]: 'If yes, for how many years in total?',
    [SMOKED_AMOUNT_OVERVIEW]: 'How many cigarettes/pipefuls a day?',
    [DOES_SMOKE]: 'Do you currently smoke cigarettes, cigars, or a pipe daily?',
    [HOW_OFTEN_DRINK]:
      'How often do you drink alcoholic beverages? (include the times when you have only small amounts, e.g. one beer)',
    [HOW_MUCH_TO_DRINK]:
      'How many units of alcohol do you usually consume on days when you drink alcohol? (unit = a small bottle or can of beer/cider/long drink, a glass of wine, a shot)',
    [HOW_OFTEN_SIX]:
      'How often do you drink at least six units of alcohol within 24 hours? (six units = six small bottles or cans of beer/cider/long drink, a bottle of wine or half a bottle of spirits)',
  },
  [HOW_OFTEN_SIX]: {
    zero: 'Never',
    one: 'Less than once a month',
    two: 'About once a month',
    three: 'About once a week',
    four: 'Daily or almost daily',
  },
  [HOW_MUCH_TO_DRINK]: {
    zero: '1-2 units',
    one: '3-4 units',
    two: '5-6 units',
    three: '7-9 units',
    four: '10 units or more',
  },
  choicebox: {
    sent: 'Sent',
    title1: 'Fill in the colorectal cancer screening pre-information form',
    title2: 'Order a new sampling kit',
    desc1: '',
    desc2: `You will need a new sampling kit if the sample tube has been damaged in any way (e.g. the tube does not contain any liquid) or if your invitation package is missing the sample tube altogether. 
    You will also need a new sampling kit if it is not possible to read the series of characters starting with H or IL, or the bar code on the sample ID sticker.
   `,
  },
  announcementsBox: {
    title: 'Notifications',
  },
  alcoholAmount: {
    'title-1': '1 UNIT',
    'description-1-1':
      '0.33 litre bottle or can of medium-strength beer or cider',
    'description-1-2': '12 cl glass of wine',
    'description-1-3': '4 cl unit of spirits',
    'title-2': '1.5 UNITS',
    'description-2-1':
      '0.5-litre bottle of medium-strength beer or 0.5-litre glass',
  },
  [HOW_OFTEN_DRINK]: {
    zero: 'I don’t use alcoholic beverages at all',
    one: 'About once a month or less frequently',
    two: '2–4 times a month',
    three: '2–3 times a week',
    four: '4 times a week or more',
  },
  forms: {
    requiredField: 'Required field',
    invalidDOB: 'Virheellinen arvo', // not used. can be removed
    maxChar6: 'Suurin merkki on 6', // not used anymore, can be removed.
    mustBeNumber: 'Please enter numbers only',
    mustBeNumberMax999:
      'Please check the input. A maximum of three digits (999) is allowed.',
    mustBeNumberMax99:
      'Please check the input. A maximum of two digits (99) is allowed.',
    mustBeNumberMax255:
      'You have exceeded the maximum number of 255 characters for this field.',
    frontPageErrorLabel: 'Incorrect sign in details {{number}}:',
    frontPageErrorDescription:
      'The sample ID or date of birth is incorrect. Please check the information you entered.',
  },
  links: {
    loginPage: 'Front page',
    logout: 'Sign out',
  },

  homePage: {
    title: 'Screening for colorectal cancer',

    infoText:
      'Colorectal cancer means cancers of the colon and the rectum. It is the third most common cancer in Finland: over 3,000 people are diagnosed with colorectal cancer every year.',
    infoText1:
      'If you have received an invite to colorectal cancer screening from HUS Diagnostic Center or ISLAB, you can use this Seula service to enter your health information related to the screening or order a new sampling kit to replace a broken or faulty one.',
  },

  errors: {
    generalError: 'Something went wrong',
    login: 'Login failed!',
    formSubmit: 'Failed to submit the form',
  },
  success: {
    formSubmit: 'Submission of form has been successful',
  },

  orderKitPage: {
    title: 'Order a new sampling kit',
    note:
      'NOTE! You will receive the new sampling kit by mail in 2 to 3 weeks after your order. Please wait patiently for the new sampling kit.',
    infoText:
      'From here you can order a new sampling kit. The content of the new sampling kit is the same as the one you received in the initial invitation package. Ordering a new sampling kit is free of charge.',
  },
  common: {
    not: 'No',
    yes: 'Yes',
    no: 'No',
    idont: 'No',
    dontKnow: "I don't know",
    thankyouEx: 'Thank you!',
  },
  buttons: {
    continue: 'Continue',
    cancel: 'Cancel',
    logout: 'Sign out',
    back: 'Edit answers',
    order: 'Order',
    send: 'Send',
    logoutWithoutSend: 'Cancel',
  },
  orderThanks: {
    infoText: 'Your order has been received.',
    title: 'Thank you for your order',
  },
  overview: {
    title: 'Summary',
    infoText:
      'Please check that the information is correct before submitting the form.',
    smokedYearsOverview: 'Vuosien määrä', // can be removed
    smokedAmountOverview: 'Savukkeiden/piippujen määrä', // can be removed
    cancelAlert:
      'Are you sure you want to cancel? All the information you provide will be lost and you will be logged out.',
  },
  [CHRONICLE_DISEASE]: {
    diabetes: 'Insulin-treated diabetes',
    flapFailure: 'Heart valve disease',
    heartFailure: 'Myocardial infarction in the past 3 months',
    severLungOrHeart: 'Severe lung or heart disease',
    severDisability: 'Severe illness limiting mobility',
    diseaseNoneOfAbove: 'None of the above',
  },
  [HAS_RELATIVES_WHO]: {
    parents: 'My parent',
    siblings: 'My sibling',
    kids: 'My child',
    otherRelative: 'Some other relative(s) (e.g. aunt, cousin)',
  },
  [MEDICINE]: {
    blood: 'Blood thinner (e.g. Marevan, Pradaxa, heparin)',
    asetylSalisyd:
      'Acetylsalicylic preparations (e.g. Asperin, Disperin, Primaspan)',
    otherMedicine: 'Other anti-inflammatory analgesic (e.g. Burana)',
    medicineNoneOfAbove: 'None of the above',
  },
  thankyou: {
    title: 'Data sent',
    infoText: 'The form has been sent successfully',
  },
  serviceInformation: {
    title: 'INFORMATION ABOUT THE SERVICE',
    'subTitle-1': 'General',
    'text-1':
      'The Seula screening service is a recording and reporting service for colorectal cancer screening provided by HUS Diagnostic Center and ISLAB to their municipal clients. The service allows screening participants to enter the necessary personal information.',
    'text-2':
      'The entered information will be visible to the professionals responsible for screening in the screening participant’s home municipality (or wellbeing services county). Professionals enter screening-related information into the service and this information is reported to the Finnish Cancer Registry after the screening or operations have concluded.',
    'subTitle-2': 'Site operation',
    'text-3':
      'The screening invitees can log in to the service by entering the sample ID they’ve received by mail and their date of birth in the format ddmmyy. Then, the user will enter the required information and, after verification, the information is stored in the service so that it can be accessed by professionals.',
    'text-4': 'The information can only be entered once.',
    'subTitle-3': 'Cookies',
    'text-5':
      'The website only uses cookies needed to maintain the user’s session. The user cannot forbid the use of necessary cookies.',
  },
  privacyPolicy: {
    title: 'PROCESSING OF PERSONAL DATA',
    'text-1':
      'The controllers of the seula.hus.fi-service are the municipalities/wellbeing services counties and the HUS Group. The service provider and processor of personal data in accordance with data protection legislation is the HUS Group.',
    'text-2':
      'Processing of personal data related to colorectal cancer screening is based on the Government Decree on Screenings (339/2011) and the changes made to Section 2 of the Decree (752/2021).',
    'text-3':
      'Information on the patient registers in accordance with the data protection legislation is available on each controller’s website.',
  },
  accessibilityStatement: {
    title: 'Seula screening service - Accessibility statement',
    'subTitle-1': 'How accessible is the website?',
    'subTitle-2': 'Did you notice any gaps in accessibility?',
    'subTitle-3': 'Accessibility monitoring',
    'subTitle-4': 'More detailed information on technical accessibility',
    'subTitle-5': 'Content or features that are not yet accessible',
    'subTitle-6': 'How have we tested accessibility?',
    'subTitle-7': 'Feedback and Contact Details',
    'text-1':
      'HUS aims to guarantee the accessibility of the service in accordance with the requirements under the law. The goal in all services is to meet the level AA accessibility criteria under the Web Content Accessibility Guidelines (WCAG 2.1).',
    'text-2':
      'This accessibility statement explains how well the Seula screening service complies with the Act on the Provision of Digital Services, what shortcomings there are in the accessibility of the website, and how you can give us feedback on accessibility problems.',
    'text-3':
      'We want as many users as possible to be able to access our digital services. We take accessibility into account in the development of digital services.',
    'text-4':
      'This online service largely meets the A and AA level accessibility criteria required by law (WCAG criteria 2.1). The online service has some shortcomings in accessibility, which are described in more detail below.',
    'text-5':
      'We are constantly striving to improve the accessibility of the online service. If you notice any accessibility issues on our website, please send your feedback to the administrator of the website first.',
    'text-6':
      'If you observe any issues that are not described on this page, please let us know and we will do our best to fix any shortcomings. We will respond to requests and comments within two weeks.',
    'text-7': 'You can give us feedback by emailing seula@hus.fi. ',
    'text-8':
      'The Regional State Administrative Agency for Southern Finland monitors the implementation of accessibility requirements nationwide. If you are not satisfied with the response or you have not received one in two weeks, you can send feedback to the Southern Finland Regional State Administrative Agency. The website of the Regional State Administrative Agency for Southern Finland provides detailed information on how to file a complaint and how the matter will be processed.',
    'text-9': `Regional State Administrative Agency of Southern Finland 
      Accessibility supervision unit 
      www.saavutettavuusvaatimukset.fi 
      saavutettavuus@avi.fi 
      Telephone (switchboard): 0295 016 000`,
    'text-10': 'The online service is not yet fully accessible. ',
    'text-11':
      '•	Certain components are not announced correctly via assistive technologies. The semantic structure of the online service is not fully programmatically determinable. (WCAG 1.3.1, 4.1.2)',
    'text-12':
      '•	Certain status messages are not announced via assistive technologies. (WCAG 4.1.3)',
    'text-13': 'HUS assesses accessibility in the following ways:',
    'text-14': '•	Self-assessment',
    'text-15':
      'We would be happy to receive suggestions, comments, questions and feedback on this service and its accessibility to our email:  seula@hus.fi.',
  },

  municipality: {
    askola: 'Askola',
    eksote: 'Eksote (Etelä-Karjalan sosiaali- ja terveyspiiri)',
    espoo: 'Espoo',
    hanko: 'Hanko',
    helsinki: 'Helsinki',
    hyvinkaa: 'Hyvinkää',
    inkoo: 'Inkoo',
    jarvenpaa: 'Järvenpää',
    karkkila: 'Karkkila',
    kauniainen: 'Kauniainen',
    kerava: 'Kerava',
    kirkkonummi: 'Kirkkonummi',
    kymsote:
      'Kymsote (Kymenlaakson sosiaali- ja terveyspalvelujen kuntayhtymä)',
    lapinjarvi: 'Lapinjärvi',
    lohja: 'Lohja',
    loviisa: 'Loviisa',
    mantsala: 'Mäntsälä',
    nurmijarvi: 'Nurmijärvi',
    pornainen: 'Pornainen',
    porvoo: 'Porvoo',
    raasepori: 'Raasepori',
    sipoo: 'Sipoo',
    siuntio: 'Siuntio',
    tuusula: 'Tuusula',
    vantaa: 'Vantaa',
    vihti: 'Vihti',
  },
};
export default translationEn;

import React from 'react';
import { TFunction } from 'i18next';
import {
  HOW_OFTEN_DRINK,
  DOES_SMOKE,
  SMOKED_AMOUNT,
  SMOKED_YEARS,
  HAS_SMOKED,
  MEDICINE_SENSITIVITY_WHAT,
  MEDICINE_SENSITIVITY,
  MEDICINE,
  HAS_RELATIVES_WHO,
  CHRONICLE_DISEASE,
  HAS_RELATIVES,
  HEIGHT,
  HOW_MUCH_TO_DRINK,
  HOW_OFTEN_SIX,
  WEIGHT,
  SMOKED_AMOUNT_OVERVIEW,
  SMOKED_YEARS_OVERVIEW,
  PHONE,
} from '../../constants/preinformationFormKeys';
import { IPreinformationFormState } from '../../state/preinfoForm/state';
import { IPreinfoFormLabels } from '../../state/settings/state';
import createPreinfoConstants from '../preInformationPageContent/PreInformationPageHelper';

export const findLabel = (
  key: string,
  preinfoFormLabels: IPreinfoFormLabels[]
) => {
  let label = '';
  preinfoFormLabels.forEach((labelItem: IPreinfoFormLabels) => {
    const labelKeys = Object.keys(labelItem);
    labelKeys.forEach((x) => {
      if (x === key) {
        label = labelItem[x];
      }
    });
  });
  return label;
};

export const defineValue = (
  key: string,
  preinfoForm: IPreinformationFormState,
  t: TFunction
) => {
  const constants = createPreinfoConstants(t);
  switch (key) {
    case HEIGHT:
    case MEDICINE_SENSITIVITY_WHAT:
    case WEIGHT:
    case PHONE:
      return preinfoForm[key];
    case SMOKED_YEARS_OVERVIEW:
      return preinfoForm[SMOKED_YEARS];
    case SMOKED_AMOUNT_OVERVIEW:
      return preinfoForm[SMOKED_AMOUNT];
    case HAS_RELATIVES:
      return constants[HAS_RELATIVES].find(
        (el) => el.value === preinfoForm[key]
      )?.label;
    case MEDICINE_SENSITIVITY:
    case HAS_SMOKED:
    case DOES_SMOKE:
      return constants.yesOrNo.find((el) => el.value === preinfoForm[key])
        ?.label;
    case HOW_OFTEN_DRINK:
    case HOW_MUCH_TO_DRINK:
    case HOW_OFTEN_SIX:
      return constants[key].find((el) => el.value === preinfoForm[key])?.label;
    case CHRONICLE_DISEASE:
    case HAS_RELATIVES_WHO:
    case MEDICINE:
      return (
        <ul>
          {Object.keys(preinfoForm[key])
            .filter((x) => preinfoForm[key][x])
            .map((y) => (
              <li key={`${key}-${y}`}>{t(`${key}.${y}`)}</li>
            ))}
        </ul>
      );
    default:
      return '';
  }
};

export const filterLabels = (
  labelKey: string,
  preinfoForm: IPreinformationFormState
) => {
  switch (labelKey) {
    case HAS_RELATIVES_WHO:
      if (preinfoForm[HAS_RELATIVES] !== 2) return false;
      break;
    case MEDICINE_SENSITIVITY_WHAT:
      if (!preinfoForm[MEDICINE_SENSITIVITY]) return false;
      break;
    case SMOKED_YEARS:
    case SMOKED_AMOUNT:
    case DOES_SMOKE:
      if (!preinfoForm[HAS_SMOKED]) return false;
      break;
    case HOW_MUCH_TO_DRINK:
    case HOW_OFTEN_SIX:
      if (!preinfoForm[HOW_OFTEN_DRINK] || preinfoForm[HOW_OFTEN_DRINK] === 1)
        return false;
      break;
    case PHONE:
      if (!preinfoForm[PHONE]) return false;
      break;
    default:
      return true;
  }
  return true;
};

export const fixLabel = (labelKey: string) => {
  switch (labelKey) {
    case SMOKED_YEARS:
      return SMOKED_YEARS_OVERVIEW;
    case SMOKED_AMOUNT:
      return SMOKED_AMOUNT_OVERVIEW;
    default:
      return labelKey;
  }
};
